<script>
export default {
  name: "DiffDateSelector",
  props: {
    diff_date: {}
  }
}
</script>

<template>
  <b-col md="2" class="p-1">
    <div class="d-flex flex-column justify-content-center align-items-center box_alternative_date p-2">
      <div>
        {{ trans('nights') }}
      </div>
      <div class="number_of_nights p-2">
        {{ diff_date.notti }}
      </div>
      <div class="">
        {{ new Date(diff_date.from) | dateFormat('DD/MM/YYYY') }} - {{
          new Date(diff_date.to)| dateFormat('DD/MM/YYYY')
        }}
      </div>
      <div class="text-justify pt-2 pb-2">
        <a class="text-uppercase" @click="$emit('select_different_date',diff_date)">
          {{ trans('select') }}
        </a>
      </div>
    </div>
  </b-col>
</template>

<style scoped lang="scss">
.box_alternative_date {
  background-color: #e7f8ff;
  color: #002f6f;
  font-size: 1em;

  .number_of_nights {
    font-size: 1.8em;
    font-weight: bolder;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>