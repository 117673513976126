<style scoped>
.sfondo_cont{
  background-color: #F4ECCD;
  border-left: 2rem solid #E28400;
}

.back_green a{
    background-color: #74B102 !important;
}
</style>

<template>
  <div class="pt-5">
    <div class="row mx-0 sfondo_cont align-items-center">
      <div class="col-xs-6 p-3">
        <div class="font-size-30 color-2">
          {{ trans("title") }}
        </div>
        <!--      <div class="font-size-14 py-3">{{trans("description")}}</div>-->
        <div class="pt-2 font-size-12">
          {{ trans("warning") }}
        </div>
      </div>
      <div class="col-xs-6 px-0 py-3 px-3 px-xs-0">
        <div class="orange_btn back_green color-white font-size-12 text-center ">
          <a class="inner_btn" :href="trans('link')"> {{ trans("link_text") }} </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CheckIn"
  }
</script>

