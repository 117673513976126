<style scoped>

</style>

<template>
  <b-row>
    <b-col md="12">
      <HeaderSoluzionePrezzi
          :data_arrivo="solution_search.data_range.checkin"
          :data_partenza="solution_search.data_range.checkout"
          :sistemazione="solution_search.sistemazione_human"
          :occupanti="solution_search.n_pax"
          :eta_partecipanti="solution_search.pax_ages"
          @reset="event_bus.$emit('reset_to_initial_search')"></HeaderSoluzionePrezzi>
    </b-col>
    <b-col md="12">
      <h3>Richiesta Informazioni</h3>
    </b-col>
    <b-col md="8">
      <BaseContactsForm :form_submit_url="submit_url()"
                        :submit_button_text="trans('procedi')"
                        @submitted="event_bus.$emit('request_info_pack_submitted',arguments[0])"></BaseContactsForm>
    </b-col>
    <b-col>
      <PackInfo></PackInfo>
    </b-col>
  </b-row>
</template>

<script>
import {mapState} from "vuex";
import BaseContactsForm from "../BaseContactsForm";
import HeaderSoluzionePrezzi from "./soluzione_prezzi/HeaderSoluzionePrezzi";
import PackInfo from "../PackInfo";

export default {
  name: "RichiestaPack",
  components: {
    BaseContactsForm,
    HeaderSoluzionePrezzi,
    PackInfo
  },
  computed: {
    ...mapState(['solution_search'])
  },
  methods: {
    submit_url() {
      return Routes.user_contact_pack_request_bookings_sessions_path;
    }
  }
}
</script>

