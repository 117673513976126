<style scoped>

</style>

<template>
  <div>
    <h1 class="title font-weight-normal font-size-36 px-0 pt-5 pb-3 pt-lg-5 col-1-mob  color-1">
      {{title}}
    </h1>
    <div class="text font-size-14 text-center-mob px-0 pb-4 line-h-2 px-lg-4">
      {{sub_title}}
    </div>
  </div>
</template>

<script>
  export default {
    name: "Header",
    props: {
      step: {}
    },
    computed: {
      title() {
        return   this.trans("title_"+this.step);
      },
      sub_title() {
        return this.trans("subtitle_"+this.step);
      }
    }
  }
</script>

