function start_datalayer_sender(store) {

  const dataLayer = window.dataLayer;
  let previousStep = '';
  let timer;
  // array carrello items + totale basket
  let basketItems = [];
  let basketTotal = 0;
  let previousListinoLength = 0;
  let previousListinoItems = [];

  function getDifference(array1, array2) {
    return array1.filter(object1 => {
      return !array2.some(object2 => {
        return object1.codice === object2.codice;
      });
    });
  }

  function getBasketData(state) {
    let itemName;
    let itemCode;
    let itemQta;
    let itemPrice;
    let itemDiscount;
    let counter;
    const elencoItems = state?.selected_solution?.solution_exposer?.listino;
    const itemCategory = state?.selected_solution?.solution_exposer?.sistemazione_human;
    const itemSolutionCode = state?.selected_solution?.id_risorsa_sgs;

    // reset
    basketItems = [];
    basketTotal = 0;

    if (elencoItems !== undefined && elencoItems.length > 0) {
      //console.log(elencoItems);

      elencoItems.map((item, key) => {
        //console.log(item);
        // controllo se è un item con prezzo
        if (item.is_promotion == false) {
          // item con prezzo
          itemName = item.descrizione;
          itemCode = item.codice;
          // se itemCode è PZ o ... (da aggiungere le altre casistiche) , metto il codice più specifico
          if (itemCode.includes('PZ') || itemCode.includes('CF') || itemCode.includes('CT')) {
            itemCode = itemSolutionCode;
          }
          itemQta = parseInt(item.qta);

          // se la quantità è maggiore di 1 devo dividere il prezzo per la quantità (mi serve il prezzo per unità)
          if (itemQta > 1) {
            itemPrice = (parseFloat(item.netto).toFixed(2) / itemQta).toFixed(2);
          } else {
            itemPrice = parseFloat(item.netto).toFixed(2);
          }

          // aggiungo il prezzo al totale
          basketTotal = parseFloat(basketTotal) + parseFloat(item.netto);

          // se itemCode = AD divido il prezzo per la quantità perchè mi serve il costo unitario per singolo adulto
          if (itemCode.includes('AD')) {
            itemPrice = (parseFloat(item.netto) / itemQta).toFixed(2);
          }
          itemDiscount = 0;
          counter = key;

          let singleItem = {
            item_id: itemCode,
            item_name: itemName,
            discount: itemDiscount,
            index: counter,
            item_brand: "Fossalta",
            item_category: itemCategory,
            price: parseFloat(itemPrice),
            quantity: itemQta
          };

          // aggiungo item all'array basketItems
          basketItems.push(singleItem);
        } else {
          // sconto
          itemName = item.descrizione;
          itemCode = 'DISCOUNT';
          itemQta = 1;
          itemPrice = 0;
          itemDiscount = parseFloat(item.netto).toFixed(2);
          counter = key;

          let singleItem = {
            item_id: itemCode,
            item_name: itemName,
            discount: itemDiscount,
            index: counter,
            item_brand: "Fossalta",
            item_category: itemCategory,
            price: parseFloat(itemPrice),
            quantity: itemQta
          };

          // aggiungo item all'array basketItems ed aggiungo il prezzo al totale
          basketItems.push(singleItem);
          basketTotal = parseFloat(basketTotal) + parseFloat(itemDiscount);
        }
      });
    }
  }

  store.subscribe((mutation, state) => {
    //console.log(state.booking_step);

    // Controllo cambio di stato "booking_step" diverso dal precedente
    if (previousStep !== state.booking_step && state.booking_step !== null) {
      previousStep = state.booking_step;
      //console.log('cambio stato: ' + previousStep);

      if (dataLayer.push !== undefined) {
        //console.dir(state);

        // step 1 - Ricerca
        if (state.booking_step === 'ricerca') {
          clearTimeout(timer);
          timer = setTimeout(() => {
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
              event: "trigger_booking_ricerca",
              eventCallback: function (e) {
                //console.log('sent booking_ricerca: ', e)
              }
            });
          }, 1000);
        }

        // step 2 - Soluzione prezzi
        if (state.booking_step === 'soluzione_prezzi') {
          clearTimeout(timer);
          timer = setTimeout(() => {
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
              event: "trigger_booking_soluzione_prezzi",
              eventCallback: function (e) {
                //console.log('sent booking_soluzione_prezzi: ', e)
              }
            });
          }, 1000);
        }

        // step 3 - Servizi extra
        if (state.booking_step === "servizi_extra") {
          // ho già selezionato una tipologia di sistemazione, 
          // 1) invio alcuni eventi add_to_cart (adulti, sistemazione, coupon, ecc.)
          // 2) invio evento begin_checkout
          // 3) invio evento booking_servizi_extra

          // recupero dati
          let itemName;
          let itemCode;
          let itemQta;
          let itemPrice;
          let itemDiscount;
          let counter;
          const elencoItems = state?.selected_solution?.solution_exposer?.listino;
          const itemCategory = state?.selected_solution?.solution_exposer?.sistemazione_human;
          const itemSolutionCode = state?.selected_solution?.id_risorsa_sgs;

          previousListinoLength = elencoItems.length;

          // 1) eventi add_to_cart
          if (elencoItems !== undefined && elencoItems.length > 0) {
            elencoItems.map((item, key) => {
              //console.log(item);
              // controllo se è un item con prezzo
              if (item.is_promotion == false) {
                // item con prezzo
                itemName = item.descrizione;
                itemCode = item.codice;
                // se itemCode è PZ o ... (da aggiungere le altre casistiche) , metto il codice più specifico
                if (itemCode.includes('PZ') || itemCode.includes('CF') || itemCode.includes('CT')) {
                  itemCode = itemSolutionCode;
                }
                itemQta = parseInt(item.qta);
                itemPrice = parseFloat(item.netto).toFixed(2);

                // aggiungo il prezzo al totale
                basketTotal = parseFloat(basketTotal) + parseFloat(itemPrice);

                // se itemCode = AD divido il prezzo per la quantità perchè mi serve il costo unitario per singolo adulto
                if (itemCode.includes('AD')) {
                  itemPrice = (parseFloat(item.netto) / itemQta).toFixed(2);
                }
                itemDiscount = 0;
                counter = key;

                let singleItem = {
                  item_id: itemCode,
                  item_name: itemName,
                  discount: itemDiscount,
                  index: counter,
                  item_brand: "Fossalta",
                  item_category: itemCategory,
                  price: parseFloat(itemPrice),
                  quantity: itemQta
                };

                // aggiungo item all'array basketItems
                basketItems.push(singleItem);

                // invio evento add_to_cart
                dataLayer.push({ ecommerce: null });
                dataLayer.push({
                  event: "add_to_cart",
                  ecommerce: {
                    currency: 'EUR',
                    value: parseFloat(itemPrice),
                    items: [singleItem]
                  }
                });
              }
            });
          }

          // 2) comunico cambio step a booking_servizi_extra
          clearTimeout(timer);
          timer = setTimeout(() => {
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
              event: "begin_checkout",
              ecommerce: {
                currency: 'EUR',
                value: parseFloat(basketTotal),
                items: basketItems
              },
              eventCallback: function (e) {
                //console.log('sent begin_checkout: ', e);

                clearTimeout(timer);
                timer = setTimeout(() => {
                  dataLayer.push({ ecommerce: null });
                  dataLayer.push({
                    event: "trigger_booking_extra",
                    ecommerce: {
                      currency: 'EUR',
                      value: parseFloat(basketTotal),
                      items: basketItems
                    },
                    eventCallback: function (e) {
                      //console.log('sent trigger_booking_extra: ', e)
                    }
                  });
                }, 1000);
              }
            });
          }, 1000);

          //console.log(basketTotal, basketItems);
        }

        // step 4 - Dati di contatti
        if (state.booking_step === "dati_di_contatto") {

          // recupero dati
          getBasketData(state);

          // comunico cambio stato a trigger_booking_dati_contatto
          clearTimeout(timer);
          timer = setTimeout(() => {
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
              event: "trigger_booking_dati_contatto",
              ecommerce: {
                currency: 'EUR',
                value: parseFloat(basketTotal),
                items: basketItems
              },
              eventCallback: function (e) {
                //console.log('sent trigger_booking_dati_contatto: ', e)
              }
            });
          }, 1000);
        }

        // step 5 - Riepilogo pagamento
        if (state.booking_step === "riepilogo_pagamento") {

          // recupero dati
          getBasketData(state);

          // comunico cambio stato a trigger_booking_riepilogo_pagamento
          clearTimeout(timer);
          timer = setTimeout(() => {
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
              event: "trigger_booking_riepilogo_pagamento",
              ecommerce: {
                currency: 'EUR',
                value: parseFloat(basketTotal),
                items: basketItems
              },
              eventCallback: function (e) {
                //console.log('sent trigger_booking_riepilogo_pagamento: ', e)
              }
            });
          }, 1000);
        }

        // step 6 - Conferma pagamento
        if (state.booking_step === "payment_process_started") {
          // recupero dati
          getBasketData(state);

          // comunico cambio stato a trigger_booking_conferma_pagamento
          clearTimeout(timer);
          timer = setTimeout(() => {
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
              event: "trigger_booking_conferma_pagamento",
              ecommerce: {
                currency: 'EUR',
                value: parseFloat(basketTotal),
                items: basketItems
              },
              eventCallback: function (e) {
                //console.log('sent trigger_booking_conferma_pagamento: ', e)
              }
            });
          }, 1000);
        }

        // step 7 - Pagamento avvenuto? (conferma?)
        if (state.booking_step === "conferma") {
          // recupero dati
          getBasketData(state);

          // comunico evento purchase
          clearTimeout(timer);
          timer = setTimeout(() => {
            let taxIva = parseFloat(basketTotal - (basketTotal / 1.22)).toFixed(2);
            let currentDate = new Date().toJSON().slice(0, 10);
            let idOrdine = currentDate + '_booking_' + state?.selected_solution?.id_risorsa_sgs;

            dataLayer.push({ ecommerce: null });
            dataLayer.push({
              event: "purchase",
              ecommerce: {
                transaction_id: idOrdine,
                currency: 'EUR',
                tax: parseFloat(taxIva),
                shipping: 0,
                value: parseFloat(basketTotal),
                items: basketItems
              },
              eventCallback: function (e) {
                //console.log('sent purchase: ', e)
              }
            });
          }, 1000);
        }
      }

    } else if (state.booking_step !== null && state.booking_step === 'servizi_extra') {
      // sono sempre nel booking_step = servizi_extra e sto aggiungendo gli extra

      // recupero dati
      let itemName;
      let itemCode;
      let itemQta;
      let itemPrice;
      let itemDiscount;
      let counter;

      let singleItemToAddOrRemove;
      let elencoItems = state?.selected_solution?.solution_exposer?.listino;
      let addOrRemoveEvent;

      const itemCategory = state?.selected_solution?.solution_exposer?.sistemazione_human;
      const itemSolutionCode = state?.selected_solution?.id_risorsa_sgs;

      if (previousListinoLength === elencoItems.length) {
        previousListinoItems = elencoItems;
      }

      if (previousListinoLength !== elencoItems.length && previousListinoLength < elencoItems.length) {
        previousListinoLength = elencoItems.length;

        // individuo il nuovo item
        singleItemToAddOrRemove = getDifference(elencoItems, previousListinoItems)[0];
        //console.log('aggiungo', singleItemToAddOrRemove);

        addOrRemoveEvent = 'add_to_cart';

      } else if (previousListinoLength !== elencoItems.length && previousListinoLength > elencoItems.length) {
        previousListinoLength = elencoItems.length;
        //console.log('ho rimosso un item', 'nuovo previousListinoLength', previousListinoLength);

        // individuo l'item da rimuovere
        singleItemToAddOrRemove = getDifference(previousListinoItems, elencoItems)[0];
        //console.log('rimuovo', singleItemToAddOrRemove);

        addOrRemoveEvent = 'remove_from_cart';
      }

      // popolo i dati ed invio l'evento
      if (singleItemToAddOrRemove?.codice !== undefined) {
        // item con prezzo
        itemName = singleItemToAddOrRemove.descrizione;
        itemCode = singleItemToAddOrRemove.codice;
        // se itemCode è PZ o ... (da aggiungere le altre casistiche) , metto il codice più specifico
        if (itemCode.includes('PZ') || itemCode.includes('CF') || itemCode.includes('CT')) {
          itemCode = itemSolutionCode;
        }
        itemQta = parseInt(singleItemToAddOrRemove.qta);

        // se la quantità è maggiore di 1 devo dividere il prezzo per la quantità (mi serve il prezzo per unità)
        if (itemQta > 1) {
          itemPrice = (parseFloat(singleItemToAddOrRemove.netto).toFixed(2) / itemQta).toFixed(2);
        } else {
          itemPrice = parseFloat(singleItemToAddOrRemove.netto).toFixed(2);
        }

        // aggiungo il prezzo al totale o lo rimuovo
        if (addOrRemoveEvent === 'add_to_cart') {
          basketTotal = parseFloat(basketTotal) + parseFloat(singleItemToAddOrRemove.netto);
        } else {
          basketTotal = parseFloat(basketTotal) - parseFloat(singleItemToAddOrRemove.netto);
        }

        // se itemCode = AD divido il prezzo per la quantità perchè mi serve il costo unitario per singolo adulto
        if (itemCode.includes('AD')) {
          itemPrice = (parseFloat(singleItemToAddOrRemove.netto) / itemQta).toFixed(2);
        }
        itemDiscount = 0;

        let singleItem = {
          item_id: itemCode,
          item_name: itemName,
          discount: itemDiscount,
          item_brand: "Fossalta",
          item_category: itemCategory,
          price: parseFloat(itemPrice),
          quantity: itemQta
        };

        // aggiungo item all'array basketItems o rimuovo
        if (addOrRemoveEvent === 'add_to_cart') {
          basketItems.push(singleItem);
        } else {
          basketItems = basketItems.filter(basketItem => basketItem.item_id !== singleItem.item_id);
        }

        // invio evento add_to_cart
        dataLayer.push({ ecommerce: null });
        dataLayer.push({
          event: addOrRemoveEvent,
          ecommerce: {
            currency: 'EUR',
            value: parseFloat(itemPrice),
            items: [singleItem]
          }
        });

        //console.log('nuovo basketItems: ', basketItems);
      }

    }
  })
}

export default start_datalayer_sender;