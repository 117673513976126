<style scoped>
.back_title{
 background-color: #EAF8FF;
  color: #01628D;
}
</style>

<template>
  <b-row>
    <b-col>
      <div class="title font-size-36 back_title px-3 py-2">
        {{ trans('seleziona_extra') }}
      </div>
      <div class="row pt-3">
        <ExtraService v-for="extra in extras" :key="extra.code" v-bind="extra"></ExtraService>
      </div>
    </b-col>
  </b-row>
</template>

<script>
  import {mapState} from "vuex";
  import ExtraService from "./ExtraService";

  export default {
    name: "ExtraServices",
    components: {
      ExtraService
    },
    computed: {
      ...mapState(['available_accessories', 'available_extras']),
      extras() {
        return this.available_accessories.concat(this.available_extras);
      }
    }
  }
</script>

