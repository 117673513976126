import axios from 'axios';
import _ from "lodash";
import Vue from 'vue';
export const EventBus = new Vue();

export function setAxiosCSRF() {
  const csrfToken = document.querySelector("meta[name=csrf-token]").content
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
  return axios;
}

export function build_StateFeedback(...fields) {

  let o = {}

  _.each(fields, (i) => {
    o[`${i}_state`] = function () {
      // console.log(this, namespace, i);
      if (this.show_errors) {
        return this.errors[i] ? false : true
      } else {
        return null;
      }
    };

    o[`${i}_feedback`] = function () {
      // console.log(this, namespace, i);
      if (this.show_errors) {
        return this.errors[i] ? this.errors[i][0] : ''
      } else {
        return '';
      }
    }
  });

  return o;

}

export const baseMixins = {
  props:{
    event_bus:{
      default(){
        return EventBus;
      }
    }
  },
  methods: {
    date_parser(value) {
      if (_.isInteger(value)) {
        return new Date(value);
      } else {
        return Date.parse(value);
      }
    },
    trans(key, opts = {}) {
      return this.$i18n.t(`vue.${this.$options._componentTag}.${key}`, opts);
    },
  },
  computed: {
    current_lang() {
      return window.current_locale;
    }
  },
  filters: {
    currency: function (value) {
      if (!value) return ''
      value = parseFloat(value)

     let numero = value
        .toFixed(2) // numero decimai
        .replace('.', ',') // sostituiamo il punto con la virgola
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')

      return "€ "+numero;

    }
  }
};