<style scoped>

</style>

<template>
  <b-row>
    <b-col md="8">
      <DatiDiContattoHeader
         :data_arrivo="selected_solution.from"
         :data_partenza="selected_solution.to"
         :sistemazione="selected_solution.solution_exposer.sistemazione_human"
         :occupanti="selected_solution.n_pax"
         :eta_partecipanti="selected_solution.pax_ages"
         :img_soluzione="selected_solution.solution_exposer.image"
         :acconto="selected_solution.solution_exposer.acconto"
         :totale="selected_solution.solution_exposer.totale"
         :nome_soluzione_selezionata="selected_solution.solution_exposer.name"
         :vehicle_model="vehicle_data.vehicle_model"
         :vehicle_dimensions="vehicle_data.vehicle_dimensions"
         :vehicle_type="vehicle_data.vehicle_type"
         :solutions_category="solution_search.solutions_category"

         @reset="event_bus.$emit('reset_to_initial_search')"
      ></DatiDiContattoHeader>
      <div class="py-4">
        <div class="font-size-36 color-1 px-3 py-2 back-col-9">Dati di prenotazione</div>
      </div>
      <BaseContactsForm :start_data="user_contact_data"
                        :form_submit_url="submit_url()"
                        :submit_button_text="trans('procedi')"
                        @submitted="event_bus.$emit('contact_data_submitted',arguments[0])"></BaseContactsForm>
    </b-col>
    <b-col md=4 class="py-3 mb-3">

      <Basket></Basket>
    </b-col>

  </b-row>
</template>

<script>
  import DatiDiContattoHeader from "./dati_di_contatto/DatiDiContattoHeader";
  import BaseContactsForm from "../BaseContactsForm";
  import {mapState} from "vuex";
  import Basket from "./Basket";

  export default {
    name: "DatiDiContatto",
    components: {
      DatiDiContattoHeader,
      BaseContactsForm,
      Basket
    },
    computed: {
      ...mapState(['solution_search', 'selected_solution','user_contact_data','vehicle_data'])
    },
    methods: {
      submit_url() {
        return Routes.contact_datas_bookings_sessions_path;
      }
    }
  }
</script>

