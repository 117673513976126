<template>
  <b-modal :visible="true" id="ok_message_modal" hide-footer hide-header class="modal_with_back" ok-only
           @hide="$emit('hide',arguments)">
    <div class="button-close pointer" @click="$bvModal.hide('ok_message_modal')">
      <span> &times</span>
    </div>
    <div class="title_ok title color-8 font-size-22 py-3">
      {{ scoped_trans("ok_message_title") }}
    </div>
    <div class="text_ok font-size-16 text-center-mob">
      {{ scoped_trans("ok_message_text") }}
    </div>
  </b-modal>
</template>
<script>
export default {
  name: 'ConfirmationModalSend',
  props: {
    scoped_trans_key: {
      type: String,
      default: "generic_message"
    }
  },
  methods: {
    scoped_trans(key) {
      return this.trans(`${this.scoped_trans_key}.${key}`);
    }
  }
}
</script>
