<style scoped>
.form-control, .custom-select {
  border: 2px solid #002F6B;
  border-radius: 0;
  font-size: 12px;
}

</style>
<style>
.custom-checkbox .custom-control-label {
  font-size: 12px !important;
}

.custom-checkbox {
  display: flex !important;
  align-items: center !important;
}

.custom-control-label::before, .custom-control-label::after {
  top: 0 !important;
}
</style>

<template>
  <b-row>
    <b-col lg="6">
      <b-form-group
          :invalid-feedback="vehicle_type_feedback"
          :state="vehicle_type_state">
        <b-form-select v-model="form.vehicle_type"
                       :state="vehicle_type_state"
                       :options="vehicle_type_options"></b-form-select>

      </b-form-group>
    </b-col>
    <b-col lg="6">
      <b-form-group
          :invalid-feedback="vehicle_dimensions_feedback"
          :state="vehicle_dimensions_state"
      >
        <b-form-input v-model="form.vehicle_dimensions" :placeholder="trans('vehicle_dimensions.label')"
                      :state="vehicle_dimensions_state"/>
      </b-form-group>
    </b-col>

    <b-col lg="6">
      <b-form-group
          :invalid-feedback="vehicle_model_feedback"
          :state="vehicle_model_state"
      >
        <b-form-input v-model="form.vehicle_model" :placeholder="trans('vehicle_model.label')"
                      :state="vehicle_model_state"/>
      </b-form-group>
    </b-col>

  </b-row>
</template>

<script>
import {build_StateFeedback} from "../helpers";
import vehicle_type_options from '../vehicle_type_options.js.erb'

export default {
  name: "VehicleDataForm",
  props: {
    form: {
      default() {
        return {};
      }
    },
    errors: {
      default() {
        return {};
      }
    },
    show_errors: {
      default: false
    }
  },
  data() {
    return {

      vehicle_type_options: vehicle_type_options((key) => this.$t(key)),

    }
  },
  computed: {
    ...build_StateFeedback(
        'vehicle_model',
        'vehicle_dimensions',
        'vehicle_type'
    )
  }
}
</script>

