<style scoped lang="scss">
  .container_app {

  .left_side {
    background-color: #C1DDEA;
  }

  }

  .loading_booking{
    background-color: rgba(193,221,234,0.5);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 5rem;
    color: #E28400;
  }
</style>

<template>
  <div class="position-relative">
    <div class="container_app">
      <b-row class="position-relative">
        <b-col>
          <b-row class="align-items-end position-relative">
            <b-col class="position-relative">
              <boot_header :step="booking_step"></boot_header>
            </b-col>

            <b-col class="px-0" lg="6" v-if="booking_step=='ricerca'">
              <checkin></checkin>
            </b-col>

          </b-row>

          <b-row>
            <b-col class="left_side position-relative">
              <ContainerStepper></ContainerStepper>
              <div v-if="global_loading" class="loading_booking">
                <i class="fa fa-refresh fa-spin"></i>
              </div>
            </b-col>
          </b-row>


          <!--      footer-->
        </b-col>

      </b-row>
    </div>

    <div class="py-3 font-size-14">
      {{ $t('vue.testo_carte') }}
    </div>
  </div>

</template>

<script>

  import boot_header from './components/Header';
  import checkin from './components/CheckIn';
  import ContainerStepper from "./components/ContainerStepper";
  import {mapState} from 'vuex';

  export default {
    components: {
      boot_header,
      checkin,
      ContainerStepper
    },
    name: "Basecontainer",
    computed: {
      ...mapState(['booking_step','global_loading'])
    }
  }
</script>

