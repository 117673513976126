<style scoped>

</style>

<template>
  <div>
    <b-row>
      <button class="close_mobile_btn d-lg-none" @click="$emit('reset')">
        &times;
      </button>
      <b-col lg="3">
        <div class="image image-w-100">
          <img class="icon" :src="img_soluzione"/>
        </div>
      </b-col>
      <b-col lg="9">
        <b-row class="pb-3">
          <b-col md="4" class="py-2 py-lg-0">
            <div class="font-size-14 label_data_top color-1">{{trans("date_of_stay")}}</div>
            <div class="row mx-0 align-items-center py-lg-2">
              <div class="col-6 px-lg-0">
                <span class="font-size-12 label_data text-uppercase">{{trans("data_arrivo")}}</span>
              </div>
              <div class="col-6 px-lg-0">
                <span class="font-size-24">
                  {{new Date(data_arrivo) | dateFormat('DD/MM')}}
                </span>
              </div>
            </div>
            <div class="row mx-0 align-items-center py-lg-2">
              <div class="col-6 px-lg-0">
                <span class="font-size-12 label_data text-uppercase">{{trans("data_partenza")}}</span>
              </div>
              <div class="col-6 px-lg-0">
                <span class="font-size-24">
                  {{new Date(data_partenza) | dateFormat('DD/MM')}}
                </span>
              </div>
            </div>
          </b-col>
          <b-col md="3" class="py-2 py-lg-0">
            <div class="font-size-14 label_data_top color-1">{{trans("sistemazione")}}</div>
            <div class="row mx-0 align-items-center py-lg-2">
              <div class="col-12 px-lg-0">
                <span class="font-size-24">
                  {{sistemazione}}
                </span>
              </div>
            </div>
          </b-col>
          <b-col md="3" class="py-2 py-lg-0">
            <div class="font-size-14 label_data_top color-1">{{trans("occupanti_label")}}</div>
            <div class="row mx-0 align-items-center py-lg-2">
              <div class="col-6 px-lg-0">
                <span class="font-size-12 label_data text-uppercase">{{trans("occupanti")}}</span>
              </div>
              <div class="col-6 px-lg-0">
                <span class="font-size-24">
                  {{occupanti}} <span class="d-lg-none">({{eta_partecipanti | join(", ")}})</span>
                </span>
              </div>
            </div>
          </b-col>
          <b-col md="2" class="py-2 py-lg-0 d-none d-lg-block">
            <div class="font-size-14 label_data_top color-1">{{trans("eta_partecipanti")}}</div>
            <div class="py-lg-2">
              <span class="font-size-24">
                  {{eta_partecipanti | join(", ")}}
              </span>
            </div>
          </b-col>
        </b-row>
        <b-row class="pb-3">
          <b-col md="3" class="py-2 py-lg-0">
            <div class="font-size-14 label_data_top color-1">{{trans("soluzione_selezionata")}}</div>
            <div class="font-size-24 col-12 px-lg-0">
              {{nome_soluzione_selezionata}}
            </div>
          </b-col>
          <b-col md="3" class="py-2 py-lg-0">
            <div class="font-size-14 label_data_top color-1">{{trans("prezzo")}}</div>
            <div class="font-size-24 col-12 px-lg-0">
              € {{totale}}
            </div>
          </b-col>
          <b-col md="3" class="py-2 py-lg-0">
            <div class="font-size-14 label_data_top color-1">{{trans("caparra")}}</div>
            <div class="font-size-24 col-12 px-lg-0">
              € {{acconto}}
            </div>
          </b-col>


        </b-row>
        <b-row class="pb-3" v-if="solutions_category===3">
          <b-col md="3" class="py-2 py-lg-0">
            <div class="font-size-14 label_data_top color-1">{{trans("vehicle_type")}}</div>
            <div class="font-size-24 col-12 px-lg-0">
              {{vehicle_type_human}}
            </div>
          </b-col>
          <b-col md="3" class="py-2 py-lg-0">
            <div class="font-size-14 label_data_top color-1">{{trans("vehicle_dimensions")}}</div>
            <div class="font-size-24 col-12 px-lg-0">
              {{vehicle_dimensions}}
            </div>
          </b-col>
          <b-col md="3" class="py-2 py-lg-0">
            <div class="font-size-14 label_data_top color-1">{{trans("vehicle_model")}}</div>
            <div class="font-size-24 col-12 px-lg-0">
              {{vehicle_model}}
            </div>
          </b-col>
        </b-row>

      </b-col>

      <b-col lg="2" class="d-lg-flex align-items-center d-none justify-content-end px-0">
        <div class="black_btn font-size-14 color-white">
          <button class="inner_btn" @click="$emit('reset')">
            {{trans("reset")}}
          </button>
        </div>
      </b-col>
    </b-row>


  </div>

  <!--  <b-row>-->
  <!--    <b-col>-->
  <!--      {{trans("data_arrivo")}} <br>-->
  <!--      {{data_arrivo}} <br>-->
  <!--      {{trans("data_partenza")}} <br>-->
  <!--      {{data_partenza}} <br>-->
  <!--      {{trans("sistemazione")}} <br>-->
  <!--      {{sistemazione}}r <br>-->
  <!--      {{trans("occupanti")}} <br>-->
  <!--      {{occupanti}} <br>-->
  <!--      {{trans("eta_partecipanti")}} <br>-->
  <!--      {{eta_partecipanti}} <br>-->
  <!--      {{trans("nome_soluzione_selezionata")}} <br>-->
  <!--      {{nome_soluzione_selezionata}} <br>-->
  <!--      {{trans("totale")}} <br>-->
  <!--      {{totale}} <br>-->
  <!--      {{trans("acconto")}} <br>-->
  <!--      {{acconto}} <br>-->
  <!--    </b-col>-->
  <!--    <b-col>-->
  <!--      <b-button @click="$emit('reset')">-->
  <!--        {{trans("reset")}}-->
  <!--      </b-button>-->
  <!--    </b-col>-->
  <!--  </b-row>-->
</template>

<script>
  import vehicle_type_options from '../../../vehicle_type_options.js.erb'
  export default {
    name: "DatiDiContattoHeader",
    props: [
      'data_arrivo',
      'data_partenza',
      'sistemazione',
      'occupanti',
      'eta_partecipanti',
      'nome_soluzione_selezionata',
      'totale',
      'acconto',
      'img_soluzione',
      'vehicle_model',
      'vehicle_dimensions',
      'vehicle_type',
      'solutions_category'
    ],
    computed:{
      vehicle_type_human(){
        return vehicle_type_options((key) => this.$t(key)).filter((value) => value.value === this.vehicle_type)[0].text
      }
    }
  }
</script>

