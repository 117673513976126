<style scoped>
  .servizio {
    background-color: #ffffff;
  }

  .bacl_select {
    background-color: #EAF8FF;
    position: relative;
  }

  .select_custom select {
    background-color: transparent;
    border: none;
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }

  .image {
    background-color: #F2F2F2;
  }

  .select_custom select option {
    text-align: center;
  }

  .btn_modal{
    width: 1rem;
    position: absolute;
    top: 50%;
    left: 2rem;
    transform: translateY(-50%);
  }
  .btn_modal svg{
    width: 100%;
  }


  .btn_loader .icon{
    display: none;
  }
  .btn_loader.loading .loading{
    display: inline-block;
  }

  .btn_loader.loaded .loaded{
    display: inline-block;
  }
</style>

<template>
  <div class="col-sm-4 py-2">

    <b-modal :id="'info_modal_'+title" modal-class="modal_servizi_info" hide-header hide-footer centered>
      <button type="button" class="button-close"  @click="$bvModal.hide('info_modal_'+title)">
         &times;
      </button>
     <div class="">
       <div v-html="description">

       </div>
     </div>
    </b-modal>


    <div class="servizio h-100 d-flex flex-column justify-content-between">
      <div class="">
        <div class="row mx-0">
          <div class="col-10 title px-3 py-2 font-size-20 color-white back-col-2">
            {{ title }}
          </div>
          <div class="col-2 p-2 d-flex align-items-center image">
            <img :src="image" class="w-100">
          </div>
        </div>

        <div class="row mx-0">
          <div class="col-12 p-3 font-size-14">
            <div v-html="short_description">

            </div>
          </div>
        </div>

      </div>


      <div class="">
        <div class="px-4 pb-3">
          <div class="row mx-0">
            <div class="col-6 p-3 back-col-10 font-size-20 text-center color-0">
              <div class="price">
                € {{ price }}
              </div>
            </div>
            <div class="col-6 px-3 bacl_select">
              <div class="select_custom">
                <select v-model="qnt">
                  <option :value="o" v-for="o in max_selector">{{ o }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 position-relative">
            <div class="orange_btn color-white font-size-12 p-2 w-100 text-center">
              <button :disabled="qnt<=0" @click="loading_state='loading';event_bus.$emit('append_extra',code,sgs_type,qnt)" class="inner_btn position-relative">{{
                trans('aggiungi') }}
                <span :class="loading_state" class="btn_loader">
                  <i class="fa fa-refresh fa-spin loading icon"></i>
                  <i class="fa fa-check loaded icon"></i>
                </span>
              </button>
            </div>
            <div class="btn_modal" v-b-modal="'info_modal_'+title">
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="47" viewBox="0 0 36 47">
                <g id="Raggruppa_975" data-name="Raggruppa 975" transform="translate(-143 -1507)">
                  <g id="Ellisse_113" data-name="Ellisse 113" transform="translate(143 1513)" fill="#f2f2f2" stroke="#707070" stroke-width="3">
                    <circle cx="18" cy="18" r="18" stroke="none"/>
                    <circle cx="18" cy="18" r="16.5" fill="none"/>
                  </g>
                  <text id="i" transform="translate(156 1507)" fill="#838383" font-size="33" font-family="SegoeUI-BoldItalic, Segoe UI" font-weight="700" font-style="italic"><tspan x="0" y="36">i</tspan></text>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    name: "ExtraService",
    props: [
      "code",
      "max_qta",
      "title",
      "description",
      "short_description",
      "price",
      "price_type",
      "image",
      "sgs_type"
    ],
    data: () => {
      return {
        qnt: 0,
        loading_state: ''
      }
    },
    mounted() {
      this.$nextTick(function () {
        this.event_bus.$on('extra_appended', (code) => {
          if(this.code==code){
            this.loading_state="loaded";
          }
        });
      })
    },
    computed: {
      max_selector() {
        if (this.max_qta == 0) {
          return [];
        }
        return _.times(this.max_qta + 1);
      }
    }
  }
</script>

