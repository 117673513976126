<script>

import {setAxiosCSRF} from "../../../helpers";
import diffDateSelector from "./DiffDateSelector.vue";

const axios = setAxiosCSRF();


export default {
  name: "DiffDateLoader",
  components: {diffDateSelector},
  props: {
    data_arrivo: {required: true},
    data_partenza: {required: true},
    solutions_category: {required: true},
    solution_id: {required: true},
  },
  data: function () {
    return {
      range_date_differenti: []
    }
  },
  created() {
    this.load_datas()
  },
  methods: {

    load_datas() {
      axios.get(Routes.load_alternative_datas_bookings_solutions_path({
        alternative_datas: {
          solutions_category: this.solutions_category,
          solution_id: this.solution_id,
          data_partenza: this.data_partenza,
          data_arrivo: this.data_arrivo
        },
        format: "json", locale: window.current_locale
      })).then((request) => {

        this.range_date_differenti = request.data.results;

      })
    }
  }
}
</script>

<template>
  <div>
    <b-row>
      <diffDateSelector v-for="diff_date in range_date_differenti"
                        :diff_date="diff_date"
                        :key="diff_date.idrisorsa+diff_date.from+diff_date.to"
                        @select_different_date="event_bus.$emit('select_different_date',arguments[0])"
      ></diffDateSelector>
    </b-row>
  </div>
</template>

<style scoped>

</style>