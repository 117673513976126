/* eslint no-console: 0 */
import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueFilterDateFormat from 'vue-filter-date-format';
import Vuex from 'vuex'
import App from '../booking/Basecontainer'
import 'es6-promise/auto'
import VueI18n from 'vue-i18n'
import _ from 'lodash'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import translator_generator from '../utils/i18n.js.erb'
import store_generator from '../booking/status_store'
import data_layer_sender from "../booking/data_layer_sender";
import {baseMixins} from "../booking/helpers";
import PackModal from "../pack_modal/PackModal";
import DataLayer from "../utils/datalayer";

let VueScrollTo = require('vue-scrollto');

// const history = createHistory()

Vue.use(VueScrollTo)
Vue.use(Vuex);
Vue.use(BootstrapVue);
Vue.use(VueI18n);
Vue.use(VueFilterDateFormat);
Vue.use(DataLayer)

Vue.mixin(baseMixins);

Vue.filter('join', function (value, join_text) {
  join_text = join_text || ', '
  if (!value) return ''
  if (_.isArray(value)) {
    return value.join(join_text);
  } else {
    return '';
  }
})

document.addEventListener('DOMContentLoaded', () => {

  const booking_el = document.getElementById('booking_form_block');
  if (booking_el) {
    const store = store_generator(Vuex);
    let attributi = {}
    _.each(booking_el.attributes, (k) => attributi[k.name] = k.value);

    _.each({
      'set_redirect_url': 'redirect-url',
      'set_admin_notification_email': 'admin-notification-email',
      'set_general_conditions_text': 'general-conditions-text',
      'set_general_conditions_url': 'general-conditions-url',
      'set_form_description_title': 'form-description-title',
      'set_form_description_text': 'form-description-text'
    }, (v, k) => {
      if (attributi[`data-${v}`]) {
        store.commit(k, attributi[`data-${v}`]);
      }
    });

    store.subscribe((mutation, state) => {
      /**
       * Aggiungiamo al datalayer degli eventi
       */
      Vue.dt_change_step(state.booking_step)
      if (state.booking_step === 'soluzione_prezzi') {
        Vue.dt_variables({
          Sistemazione: state.solution_search.sistemazione_human,
          NumPersons: parseInt(state.solution_search.n_pax)
        })
      }
      if(state.booking_step === "servizi_extra"){
        Vue.dt_variables({
          Sistemazione: state.solution_search.sistemazione_human,
          NumPersons: parseInt(state.solution_search.n_pax),
          Tipologia:state.selected_solution.solution_exposer.name
        })
      }
    })

    // inizializziamo ascoltatore store per datalayer e spedizione dati
    data_layer_sender(store);


    new Vue({
      el: booking_el,
      store: store,
      i18n: translator_generator(VueI18n, window.current_locale),
      render: h => h(App)
    })

  }

  let elenco_modals = [...document.getElementsByClassName('pack_modal_link')];

  _.forEach(elenco_modals, (v) => {

    const values = {
      pack_id: v.attributes['data-pack-id'].value,
      booking_url: v.attributes['data-booking-url'].value
    }

    new Vue({
      el: v,
      data: function () {
        return values
      },
      i18n: translator_generator(VueI18n, window.current_locale),
      render: h => h(PackModal)
    })
  });


})
