<style scoped>

</style>

<template>
  <b-row>
    <b-col md="12">
      <DatiDiContattoHeader
         :data_arrivo="solution_search.data_range.checkin"
         :data_partenza="solution_search.data_range.checkout"
         :sistemazione="solution_search.sistemazione_human"
         :occupanti="solution_search.n_pax"
         :eta_partecipanti="solution_search.pax_ages"
         :acconto="selected_solution.solution_exposer.acconto"
         :totale="selected_solution.solution_exposer.totale"
         :nome_soluzione_selezionata="selected_solution.solution_exposer.name"
         @reset="event_bus.$emit('reset_to_initial_search')"
      ></DatiDiContattoHeader>
    </b-col>
    <b-col md="12">
      <h3>Richiesta Informazioni</h3>
    </b-col>
    <b-col md="12">
      <BaseContactsForm :form_submit_url="submit_url()"
                        :submit_button_text="trans('procedi')"
                        @submitted="event_bus.$emit('request_info_specific_submitted',arguments[0])"></BaseContactsForm>
    </b-col>
  </b-row>
</template>

<script>
  import DatiDiContattoHeader from "./dati_di_contatto/DatiDiContattoHeader";
  import BaseContactsForm from "../BaseContactsForm";
  import {mapState} from "vuex";

  export default {
    name: "RichiestaInfoSpecifiche",
    components: {
      DatiDiContattoHeader,
      BaseContactsForm
    },
    computed: {
      ...mapState(['solution_search', 'selected_solution'])
    },
    methods: {
      submit_url() {
        return Routes.specific_info_request_bookings_sessions_path;
      }
    }
  }
</script>

