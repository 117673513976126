<style scoped>
  .form-control, .custom-select {
    border: 2px solid #002F6B;
    border-radius: 0;
    font-size: 12px;
  }

</style>
<style>
  .custom-checkbox .custom-control-label {
    font-size: 12px !important;
  }

  .custom-checkbox {
    display: flex !important;
    align-items: center !important;
  }

  .custom-control-label::before, .custom-control-label::after {
    top: 0 !important;
  }
</style>

<template>
  <b-form @submit="onSubmit">
    <slot name="prepend" :form="form" :errors="errors" :show_errors="show_errors"></slot>
    <b-row class="justify-content-center">
      <b-col lg="8">


        <b-row>
          <b-col lg="6">
            <b-form-group
                :invalid-feedback="name_feedback"
                :state="name_state">
              <b-form-input v-model="form.name" :placeholder="trans('name.label')" :state="name_state"/>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group
                :invalid-feedback="surname_feedback"
                :state="surname_state"
            >
              <b-form-input v-model="form.surname" :placeholder="trans('surname.label')" :state="surname_state"/>
            </b-form-group>
          </b-col>

          <b-col lg="6">
            <b-form-group
                :invalid-feedback="address_feedback"
                :state="address_state"
            >
              <b-form-input v-model="form.address" :placeholder="trans('address.label')" :state="address_state"/>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group
                :invalid-feedback="city_feedback"
                :state="city_state"
            >
              <b-form-input v-model="form.city" :placeholder="trans('city.label')" :state="city_state"/>
            </b-form-group>
          </b-col>

          <b-col lg="6">
            <b-form-group
                :invalid-feedback="zip_code_feedback"
                :state="zip_code_state"
            >
              <b-form-input v-model="form.zip_code" :placeholder="trans('zip_code.label')" :state="zip_code_state"/>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group
                :invalid-feedback="province_feedback"
                :state="province_state"
            >
              <b-form-input v-model="form.province" :placeholder="trans('province.label')" :state="province_state"/>
            </b-form-group>
          </b-col>

          <b-col lg="6">
            <b-form-group
                :invalid-feedback="state_id_feedback"
                :state="state_id_state"
            >
              <b-form-select v-model="form.state_id"
                             :state="state_id_state"
                             :options="state_options"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6"></b-col>

          <b-col lg="6">
            <b-form-group
                :invalid-feedback="telephone_feedback"
                :state="telephone_state"
            >
              <b-form-input v-model="form.telephone" :placeholder="trans('telephone.label')" :state="telephone_state"/>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group
                :invalid-feedback="email_feedback"
                :state="email_state"
                type="email"
            >
              <b-form-input v-model="form.email" :placeholder="trans('email.label')" :state="email_state"/>
            </b-form-group>
          </b-col>

          <b-col lg="12">
            <b-form-group
                :invalid-feedback="mess_feedback"
                :state="mess_state"
            >
              <b-form-textarea v-model="form.mess" :placeholder="trans('mess.label')" rows="3" :state="mess_state"
                               max-rows="6"/>
            </b-form-group>
          </b-col>
        </b-row>


        <b-form-group
            :invalid-feedback="check_privacy_feedback"
            :state="check_privacy_state"
            :placeholder="trans('check_privacy.label')">
          <b-form-checkbox
              v-model="form.check_privacy"
              :value="true" :state="check_privacy_state"
              :unchecked-value="false">
            {{ trans('check_privacy.label') }}
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
            :invalid-feedback="check_newsletter_feedback"
            :state="check_newsletter_state"
            :placeholder="trans('check_newsletter.label')">
          <b-form-checkbox
              v-model="form.check_newsletter"
              :value="true" :state="check_newsletter_state"
              :unchecked-value="false">
            {{ trans('check_newsletter.label') }}
          </b-form-checkbox>
        </b-form-group>
        <div class="text-center">
          <div class="orange_btn font-size-16 color-white">
            <button type="submit" variant="primary" class="px-5 inner_btn">{{ submit_button_text }}</button>
          </div>
          <div class="color-1 font-size-12 pt-1">{{ trans('riepilogo') }}</div>
        </div>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
  import {build_StateFeedback, setAxiosCSRF} from "../helpers";
  import state_options from '../state_options.js.erb'

  const axios = setAxiosCSRF();
  export default {
    name: "BaseContactsForm",
    props: {
      /**
       * Devo passare la funzione di Routejs
       */
      form_submit_url: {
        required: true
      },
      submit_button_text: {
        default() {
          return this.trans("submit")
        }
      },
      start_data:{
        default(){
          return {};
        }
      },
      /*Se vogliamo aggiungere ulteriori dati da spedire*/
      more_data:{
        default(){
          return {};
        }
      }
    },
    data() {

      let form_data = Object.assign({
        name: null,
        surname: null,
        address: null,
        city: null,
        zip_code: null,
        province: null,
        state_id: null,
        telephone: null,
        email: null,
        mess: null,
        check_privacy: false,
        check_newsletter: false,
        ...this.more_data
      },this.start_data)

      form_data.check_privacy = !!form_data.check_privacy
      form_data.check_newsletter = !!form_data.check_newsletter

      return {
        form: form_data,

        state_options: state_options((key) => this.$t(key)),
        errors: {},
        show_errors: false
      }
    },
    computed: {
      ...build_StateFeedback(
        'name',
        'surname',
        'address',
        'city',
        'zip_code',
        'province',
        'state_id',
        'telephone',
        'email',
        'mess',
        'check_privacy',
        'check_newsletter'
      )
    },
    methods: {
      onSubmit(e) {
        e.preventDefault();
        axios.post(this.form_submit_url({
          format: 'json',
          locale: window.current_locale
        }), {form: this.form}).then((request) => {

          if (request.data.success) {
            this.show_errors = false;
            // quando abbiamo completato la spedizione dei dati mandiamo questo evento
            this.$emit('submitted', this.form);
          } else {
            this.show_errors = true;
            this.errors = Object.assign({}, request.data.errors);
          }

        });


      }
    }
  }
</script>

