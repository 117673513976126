<style scoped>
  .icon-col {
    background-color: #74B102;
  }
</style>

<template>
  <b-row>
    <b-col md="12">

      <b-row>
        <b-col lg="1" class="icon-col py-3">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="60.5" height="60.5" viewBox="0 0 60.5 60.5">
              <g id="Group_877" data-name="Group 877" transform="translate(-5 -5)">
                <path id="Path_598" data-name="Path 598"
                      d="M35.25,5A30.25,30.25,0,1,0,65.5,35.25,30.271,30.271,0,0,0,35.25,5Zm0,2.689A27.561,27.561,0,1,1,7.689,35.25,27.541,27.541,0,0,1,35.25,7.689ZM23.822,22.478a4.033,4.033,0,1,0,4.033,4.033A4.033,4.033,0,0,0,23.822,22.478Zm22.856,0a4.033,4.033,0,1,0,4.033,4.033A4.033,4.033,0,0,0,46.678,22.478Zm3.34,19.39a1.369,1.369,0,0,0-1.092.693,16.243,16.243,0,0,1-27.351,0,1.373,1.373,0,0,0-1.26-.609,1.416,1.416,0,0,0-.966,2.1,18.912,18.912,0,0,0,31.8,0A1.37,1.37,0,0,0,50.018,41.867Z"
                      fill="#fff"/>
              </g>
            </svg>
          </div>
        </b-col>

        <b-col>

          <div class="font-size-30 color-1 font-weight-bold col-12">
            {{trans('titolo')}}
          </div>
          <div class="font-size-24 line-h-2 color-1 pb-3 col-12" v-html=" trans('testo')"></div>


          <b-col md="6">
            <div class="d-flex mx-0 align-items-center py-2">
              <div class="font-size-14 label_data_top color-1">{{trans("name")}}</div>
              <div class="px-3">
                <span class="font-size-24">
                  {{ name }}
                </span>
              </div>
            </div>
          </b-col>
          <b-col md="6" class="">
            <div class="d-flex mx-0 align-items-center py-2">
              <div class="font-size-14 label_data_top color-1">{{trans("surname")}}</div>
              <div class="px-3">
                <span class="font-size-24">
                  {{ surname }}
                </span>
              </div>
            </div>
          </b-col>
          <b-col md="6" class="">
            <div class="d-flex mx-0 align-items-center py-2">
              <div class="font-size-14 label_data_top color-1">{{trans("email")}}</div>
              <div class="px-3">
                <span class="font-size-24">
                  {{ email }}
                </span>
              </div>
            </div>
          </b-col>

          <b-col md="6">
            <div class="d-flex mx-0 align-items-center py-2">
              <div class="font-size-14 label_data_top color-1">{{trans("date_of_stay")}}</div>
              <div class="px-3">
                <span class="font-size-24">
                  {{new Date(data_arrivo) | dateFormat('DD/MM')}} -  {{new Date(data_partenza) | dateFormat('DD/MM')}}
                </span>
              </div>
            </div>
          </b-col>

          <b-col md="6">
            <div class="d-flex mx-0 align-items-center py-2">
              <div class="font-size-14 label_data_top color-1">{{trans("sistemazione")}}</div>
              <div class="px-3">
                <span class="font-size-24">
                  {{ sistemazione }}
                </span>
              </div>
            </div>
          </b-col>


        </b-col>
      </b-row>
      </b-col>
      <b-col md="12" class="text-center py-3">
        <div class="orange_btn color-white font-size-14">
          <button class="inner_btn px-5" @click="$emit('continue')">{{trans('continua_a_navigare')}}</button>
        </div>
      </b-col>
    </b-row>

</template>

<script>
  export default {
    name: "Confirmation",
    props: [
      'name',
      'surname',
      'email',
      'data_arrivo',
      'data_partenza',
      'sistemazione'
    ]
  }
</script>

