<!--Componente che si occupa di  contenere breadcrumbs e formvarie-->

<template>
  <b-row class="m-0">

    <b-col class="px-0">
      <BreadCrumbs v-if="show_breadcrumbs" :step="booking_step" @move_to="breadcrumbs_change_step"></BreadCrumbs>
      <div class="p-3">
        <template v-if="booking_step=='ricerca'">
          <div class="col-lg-8 px-0">
            <Ricerca v-model="solution_search"></Ricerca>
          </div>
        </template>
        <template v-else-if="booking_step=='soluzione_prezzi'">
          <SoluzionePrezzi :solutions="internal_data.solutions"></SoluzionePrezzi>
        </template>
        <template v-else-if="booking_step=='servizi_extra'">
          <ServiziExtra></ServiziExtra>
        </template>
        <template v-else-if="booking_step=='dati_di_contatto'">
          <DatiDiContatto></DatiDiContatto>
        </template>
        <template v-else-if="booking_step=='riepilogo_pagamento'">
          <RiepilogoPagamento></RiepilogoPagamento>
        </template>
        <template v-else-if="booking_step=='richiesta_generica_pack'">
          <RichiestaPack></RichiestaPack>
        </template>
        <template v-else-if="booking_step=='payment_process_started'">
          <PagamentoStarted :redirect_url="bank_redirect_url"></PagamentoStarted>
        </template>
        <template v-else-if="booking_step=='conferma'">
          <Confirmation @continue="event_bus.$emit('reset_and_exit')"
                        :name="user_contact_data.name"
                        :surname="user_contact_data.surname"
                        :email="user_contact_data.email"
                        :data_arrivo="selected_solution.from"
                        :data_partenza="selected_solution.to"
                        :sistemazione="selected_solution.solution_exposer.name"
          ></Confirmation>
        </template>
        <template v-else-if="booking_step=='payment_verification_failed'">
          <Failure @submitted="event_bus.$emit('help_failure_submitted')"
                   :name="user_contact_data.name"
                   :surname="user_contact_data.surname"
                   :email="user_contact_data.email"
                   :data_arrivo="selected_solution.from"
                   :data_partenza="selected_solution.to"
                   :sistemazione="selected_solution.solution_exposer.sistemazione_human"
          ></Failure>
        </template>
        <template v-else-if="booking_step=='richiesta_info_spec'">
          <RichiestaInfoSpecifiche></RichiestaInfoSpecifiche>
        </template>
        <template v-else-if="booking_step=='richiesta_generica'">
          <RichiestaGenerica></RichiestaGenerica>
        </template>
        <template v-else-if="booking_step=='request_information_sended'">
          <ConfirmationModalSend @hide="event_bus.$emit('reset_to_initial_search')"/>
        </template>
        <template v-else-if="booking_step=='request_information_pack_sended'">
          <ConfirmationModalSend @hide="event_bus.$emit('reset_to_initial_pack')"/>
        </template>
        <template v-else-if="booking_step=='help_failure_submitted'">
          <b-modal :visible="true" class="modal_with_back" ok-only @ok="event_bus.$emit('reset_to_initial_search')">
            Conferma spedizione richiesta aiuto
          </b-modal>
        </template>
        <template v-else-if="booking_step!='' && booking_step!=null">
          Situazione non prevista <br>
          <b-button @click="event_bus.$emit('reset_to_initial_search')">{{trans("reset")}}</b-button>
        </template>
      </div>

    </b-col>

  </b-row>

</template>

<script>
  import {mapState} from "vuex";
  import BreadCrumbs from "./BreadCrumbs";
  import Ricerca from "./steps/Ricerca";
  import SoluzionePrezzi from "./steps/SoluzionePrezzi";
  import DatiDiContatto from "./steps/DatiDiContatto";
  import RiepilogoPagamento from "./steps/RiepilogoPagamento";
  import RichiestaInfoSpecifiche from "./steps/RichiestaInfoSpecifiche";
  import RichiestaGenerica from "./steps/RichiestaGenerica";
  import RichiestaPack from "./steps/RichiestaPack";
  import PagamentoStarted from "./steps/final/PagamentoStarted";
  import Confirmation from "./steps/final/Confirmation";
  import Failure from "./steps/final/Failure";
  import ConfirmationModalSend from "./ConfirmationModalSend";
  import ServiziExtra from "./steps/ServiziExtra";
  import {setAxiosCSRF} from "../helpers";

  const axios = setAxiosCSRF();

  export default {
    components: {
      ConfirmationModalSend,
      BreadCrumbs,
      Ricerca,
      SoluzionePrezzi,
      DatiDiContatto,
      RiepilogoPagamento,
      RichiestaInfoSpecifiche,
      RichiestaGenerica,
      PagamentoStarted,
      Confirmation,
      Failure,
      RichiestaPack,
      ServiziExtra
    },
    data: function () {
      return {

        internal_data: {
          solutions: []
        }

      }
    },
    created() {
      /**
       * Creo una serie di eventi ascoltatori
       */
      this.event_bus.$on('search_results', (soluzioni) => {
        this.internal_data.solutions = soluzioni;
        this.$store.dispatch('change_step', 'soluzione_prezzi');
      });

      this.event_bus.$on("select_different_date", (diff_date) => {

        this.event_bus.$emit("reset_to_initial_search")
        let dati = Object.assign({}, this.solution_search, {
          data_range: {
            "checkin": diff_date.from,
            "checkout": diff_date.to
          }
        });

        axios.post(Routes.search_bookings_solutions_path({
          format: "json",
          locale: window.current_locale
        }), {solution_search: dati}).then((request) => {

          if (request.data.success) {
            this.event_bus.$emit('search_results', request.data.results);
          } else {
            console.error("Problemi di qualche sorta non previsti:", request.data.errors);
          }

        })
      });

      this.event_bus.$on('reset_to_initial_search', () => {
        this.$store.dispatch('reset_booking_process');
      });

      this.event_bus.$on('reset_and_exit', () => {
        this.$store.dispatch('reset_booking_process').then(() => {
          window.location = "/";
        });
      });

      this.event_bus.$on('help_failure_submitted', () => {
        this.$store.dispatch('change_step', 'help_failure_submitted');
      });

      this.event_bus.$on('information_solution_selected', (solution) => {
        this.$store.dispatch('solution_selected', solution.solution_identification).then(() => {
          this.$store.dispatch('change_step', 'richiesta_info_spec');
        });
      });

      this.event_bus.$on('generic_request', () => {
        this.$store.dispatch('change_step', 'richiesta_generica');
      });

      this.event_bus.$on('solution_selected', (solution) => {
        this.$store.dispatch('solution_selected', solution.solution_identification).then(() => {
          this.$store.dispatch('change_step', 'servizi_extra');
        });
      })

      this.event_bus.$on('append_extra', (code, sgs_type, qnt) => {
        this.$store.dispatch('append_extra', {code, sgs_type, qnt}).then(() => {
          this.event_bus.$emit('extra_appended', code, sgs_type);
        });
      })
      this.event_bus.$on('remove_extra', (code) => {
        this.$store.dispatch('remove_extra', {code})
      })

      this.event_bus.$on('to_contact_datas', () => {
        this.$store.dispatch('change_step', 'dati_di_contatto');
      });

      this.event_bus.$on('contact_data_submitted', (dati) => {
        this.$store.dispatch('change_step', 'riepilogo_pagamento');
      })

      this.event_bus.$on('request_info_specific_submitted', (dati) => {
        this.$store.dispatch('change_step', 'request_information_sended');
      })

      this.event_bus.$on('request_info_generic_submitted', (dati) => {
        this.$store.dispatch('change_step', 'request_information_sended');
      })

      this.event_bus.$on('request_info_pack_submitted', (dati) => {
        this.$store.dispatch('change_step', 'request_information_pack_sended');
      })
      this.event_bus.$on('reset_to_initial_pack', (dati) => {
        let id_pack = this.selected_pack.id;
        this.$store.dispatch('reset_booking_process').then(() => {
          window.location = Routes.redirect_to_packet_bookings_sessions_path({packet_id: id_pack})
        });
      })

      this.event_bus.$on('start_payment_process', () => {
        this.$store.dispatch('start_payment_process').then(({booking_step}) => {

          // abbiamo ora lo status del processo di pagamento
          if (booking_step == 'payment_process_started') {
            console.info("INIZIATO IL PROCESSO");
          } else {
            console.info("IL PROCESSO HA AVUTO UN PROBLEMA AD INIZIALIZZARE");
          }

        });
      })


    },
    name: "ContainerStepper",
    computed: {
      ...mapState(['booking_step', 'solution_search', 'bank_redirect_url', 'selected_solution', 'selected_pack', 'user_contact_data']),
      show_breadcrumbs() {
        /*
        * Nel caso in cui siamo nei pacchetti, nascondiamo il breadcrumbs
        */
        if (this.selected_pack && this.selected_pack.id) {
          return false;
        }
        return true;
      }
    },
    watch: {
      booking_step:  function(value) {
        this.scrollTop();
        return value;
      }
    },
    methods: {
      scrollTop() {
        window.scrollTo(0,0);
      },
      breadcrumbs_change_step(step) {
        this.$store.dispatch('change_step', step);

      }
    }
  }
</script>

