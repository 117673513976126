<style scoped>
  .divider {
    height: 1px;
    /*background-color: #FFFFFF;*/
  }
</style>

<template>
  <b-row>
    <b-col>
      <b-row class="pb-3">
        <b-col md="6">
          <div class="font-size-14 label_data_top color-1 pt-3 pt-lg-0">{{trans("date_of_stay")}}</div>
          <div class="row mx-0 align-items-center py-lg-2">
            <div class="col-6 px-lg-0">
              <span class="font-size-12 label_data text-uppercase">{{trans("data_arrivo")}}</span>
            </div>
            <div class="col-6 px-lg-0">
          <span class="font-size-24">
            {{new Date(data_arrivo) | dateFormat('DD/MM')}}
          </span>
            </div>
          </div>
          <div class="row mx-0 align-items-center py-lg-2">
            <div class="col-6 px-lg-0">
              <span class="font-size-12 label_data text-uppercase">{{trans("data_partenza")}}</span>
            </div>
            <div class="col-6 px-lg-0">
          <span class="font-size-24">
            {{new Date(data_partenza) | dateFormat('DD/MM')}}
          </span>
            </div>
          </div>
        </b-col>
        <b-col md="6" class="">
          <div class="font-size-14 label_data_top color-1 pt-3 pt-lg-0">{{trans("soluzione_selezionata")}}</div>
          <div class="font-size-24 col-12 px-lg-0">
            {{nome_soluzione_selezionata}}
          </div>
        </b-col>

        <b-col md="12" class="py-lg-2">
          <div class="divider"></div>
        </b-col>

        <b-col md="6">
          <div class="font-size-14 label_data_top color-1 pt-3 pt-lg-0">{{trans("sistemazione")}}</div>
          <div class="row mx-0 align-items-center py-lg-2">
            <div class="col-12 px-lg-0">
              <div class="font-size-24  col-12 px-lg-0">
                {{sistemazione}}
              </div>
            </div>
          </div>
        </b-col>

        <b-col md="6">
          <div class="font-size-14 label_data_top color-1 pt-3 pt-lg-0">{{trans("occupanti_label")}}</div>
          <div class="row mx-0 align-items-center py-lg-2">
            <div class="col-6 px-lg-0">
              <span class="font-size-12 label_data text-uppercase">{{trans("occupanti")}}</span>
            </div>
            <div class="col-6 px-lg-0">
              <div class="font-size-24  col-12 px-lg-0">
                {{occupanti}} <span class="d-lg-none">({{eta_partecipanti | join(", ")}})</span>
              </div>
            </div>
          </div>
        </b-col>

        <b-col md="12" class="py-2">
          <div class="divider"></div>
        </b-col>

        <b-col md="6" class="d-none d-lg-block">
          <div class="font-size-14 label_data_top color-1 pt-3 pt-lg-0">{{trans("eta_partecipanti")}}</div>
          <div class="py-2">
            <div class="font-size-24  col-12 px-lg-0">
              {{eta_partecipanti | join(", ")}}
            </div>
          </div>
        </b-col>
        <b-col md="6">
          <div class="font-size-14 label_data_top color-1 pt-3 pt-lg-0">{{trans("prezzo")}}</div>
          <div class="font-size-24  col-12 px-lg-0">
            € {{totale}}
          </div>
        </b-col>

        <b-col md="12" class="py-2">
          <div class="divider"></div>
        </b-col>


        <b-col md="6">
          <div class="font-size-14 label_data_top color-1 pt-3 pt-lg-0">{{trans("caparra")}}</div>
          <div class="font-size-24 col-12 px-lg-0">
            € {{acconto}}
          </div>
        </b-col>

      </b-row>

      <!--      {{trans("sistemazione")}} <br>-->
      <!--      {{sistemazione}}r <br>-->
      <!--      {{trans("occupanti")}} <br>-->
      <!--      {{occupanti}} <br>-->
      <!--      {{trans("eta_partecipanti")}} <br>-->
      <!--      {{eta_partecipanti}} <br>-->
      <!--      {{trans("nome_soluzione_selezionata")}} <br>-->
      <!--      {{nome_soluzione_selezionata}} <br>-->
      <!--      {{trans("totale")}} <br>-->
      <!--      {{totale}} <br>-->
      <!--      {{trans("acconto")}} <br>-->
      <!--      {{acconto}} <br>-->
    </b-col>
  </b-row>
</template>

<script>
  export default {
    name: "DatiSoggiorno",
    props: [
      'data_arrivo',
      'data_partenza',
      'sistemazione',
      'occupanti',
      'eta_partecipanti',
      'nome_soluzione_selezionata',
      'totale',
      'acconto'
    ]
  }
</script>

