<style>
.close_mobile_btn{
  background: #000000;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #FFFFFF;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 15px;
  z-index: 2;
}
</style>

<template>
  <b-row class="mx-0 pb-3">
    <button class="close_mobile_btn d-lg-none" @click="$emit('reset')">
      &times;
    </button>
    <b-col md="3" class="px-0">
      <div class="font-size-14 label_data_top color-1">{{trans("date_of_stay")}}</div>
      <div class="row mx-0 align-items-center py-lg-2">
        <div class="col-6 px-lg-0">
          <span class="font-size-12 label_data text-uppercase">{{trans("data_arrivo")}}</span>
        </div>
        <div class="col-6 px-lg-0">
          <span class="font-size-24">
            {{new Date(data_arrivo) | dateFormat('DD/MM')}}
          </span>
        </div>
      </div>
      <div class="row mx-0 align-items-center py-lg-2">
        <div class="col-6 px-lg-0">
          <span class="font-size-12 label_data text-uppercase">{{trans("data_partenza")}}</span>
        </div>
        <div class="col-6 px-lg-0">
          <span class="font-size-24">
            {{new Date(data_partenza) | dateFormat('DD/MM')}}
          </span>
        </div>
      </div>
    </b-col>
    <b-col md="4" class="px-0">
      <div class="font-size-14 label_data_top color-1 pt-3 pt-lg-0">{{trans("sistemazione")}}</div>
      <div class="row mx-0 align-items-center py-lg-2">
        <div class="col-5 px-lg-0">
          <span class="font-size-12 label_data text-uppercase">{{trans("sistemazione")}}</span>
        </div>
        <div class="col-7 px-lg-0">
          <span class="font-size-24">
            {{sistemazione}}
          </span>
        </div>
      </div>
    </b-col>
    <b-col md="3" class="px-0">
      <div class="font-size-14 label_data_top color-1 pt-3 pt-lg-0">{{trans("occupanti_label")}}</div>
      <div class="row mx-0 align-items-center py-lg-2">
        <div class="col-6 px-lg-0">
          <span class="font-size-12 label_data text-uppercase">{{trans("occupanti")}}</span>
        </div>
        <div class="col-6 px-lg-0">
          <span class="font-size-24">
            {{occupanti}} <span class="d-lg-none">({{eta_partecipanti | join(", ")}})</span>
          </span>
        </div>
      </div>
    </b-col>
    <b-col md="2" class="px-0 d-none d-lg-block">
      <div class="font-size-14 label_data_top color-1 pt-3 pt-lg-0">{{trans("eta_partecipanti")}}</div>
      <div class="py-lg-2">
        <span class="font-size-24">
            {{eta_partecipanti | join(", ")}}
        </span>
      </div>
    </b-col>
    <b-col md="12" class="d-lg-flex align-items-center justify-content-end px-0 pt-3 pt-lg-0 d-none">
      <div class="black_btn font-size-14 color-white">
        <button class="inner_btn" @click="$emit('reset')">
          {{trans("reset")}}
        </button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
  export default {
    name: "HeaderSoluzionePrezzi",
    props: [
      'data_arrivo',
      'data_partenza',
      'sistemazione',
      'occupanti',
      'eta_partecipanti'
    ]
  }
</script>

