<style scoped>
.back_title {
  background-color: #EAF8FF;
  color: #01628D;
}
</style>


<template>

  <b-row>
    <b-col>
      <div class="title font-size-36 back_title px-3 py-2">
        {{ trans('dati_generali') }}
      </div>
      <div class="row pt-3">
        <b-col>
          <b-form>
            <VehicleData :form.sync="form"
                         :errors="errors"
                         :show_errors="show_errors"></VehicleData>
          </b-form>
        </b-col>
      </div>
    </b-col>
  </b-row>

</template>

<script>
import VehicleData from "../../VehicleData";
import {setAxiosCSRF} from "../../../helpers";

const axios = setAxiosCSRF();
export default {
  name: "VehicleDataForm",
  components: {
    VehicleData
  },
  data: function () {
    return {
      form: {
        vehicle_model: null,
        vehicle_dimensions: null,
        vehicle_type: null
      },
      errors: {},
      show_errors: false
    }
  },
  methods: {
    submit() {
      return new Promise((resolve) => {
        axios.post(Routes.update_vehicle_datas_bookings_sessions_path({
          format: 'json',
          locale: window.current_locale
        }), {form: this.form}).then((request) => {
          if (request.data.success) {
            this.show_errors = false;
            resolve(request);
          } else {
            this.show_errors = true;
            this.errors = Object.assign({}, request.data.errors);
            resolve(request);
          }

        });
      })
    }
  }
}
</script>

