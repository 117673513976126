<style scoped>

</style>

<template>
  <b-row>
    <b-col lg="3" class="d-none d-lg-block">
      <b-img :src="selected_solution.solution_exposer.image"></b-img>
    </b-col>

    <b-col lg="4">

      <DatiSoggiorno

         :data_arrivo="selected_solution.from"
         :data_partenza="selected_solution.to"
         :sistemazione="selected_solution.solution_exposer.sistemazione_human"
         :occupanti="selected_solution.n_pax"
         :eta_partecipanti="selected_solution.pax_ages"

         :acconto="selected_solution.solution_exposer.acconto"
         :totale="selected_solution.solution_exposer.totale"
         :nome_soluzione_selezionata="selected_solution.solution_exposer.name"

      ></DatiSoggiorno>

    </b-col>

    <b-col lg="5">
      <DatiPrenotazione
         :name="user_contact_data.name"
         :surname="user_contact_data.surname"
         :address="user_contact_data.address"
         :city="user_contact_data.city"
         :zip_code="user_contact_data.zip_code"
         :province="user_contact_data.province"
         :state_id="user_contact_data.state_id"
         :telephone="user_contact_data.telephone"
         :email="user_contact_data.email"
         :mess="user_contact_data.mess"
      ></DatiPrenotazione>
    </b-col>





    <b-col lg="3" class="d-none d-lg-block">

    </b-col>

    <b-col lg="4">
      <Basket :read_only="true"></Basket>
    </b-col>

    <b-col lg="5">

    </b-col>

    <b-col lg="3" class="d-none d-lg-block">

    </b-col>

    <b-col lg="4">
      <b-form-group>
        <b-form-checkbox v-model="condizioni_generali_check" :unchecked-value="false">
          <span v-html="replace_condizioni_url(trans('condizioni_generali'))"></span>
        </b-form-checkbox>
      </b-form-group>
    </b-col>


    <b-col md="12" class="text-center py-3">
      <div class="orange_btn color-white font-size-16">
        <button class="inner_btn px-5" :disabled="!condizioni_generali_check"
                @click="event_bus.$emit('start_payment_process')">{{ trans('procedi') }}</button>
      </div>
      <div class="color-1 font-size-12 pt-1">{{ trans('pagamento') }}</div>
    </b-col>
    <b-col md="12" class="text-center py-5">
      <div class="black_btn color-white font-12-mob font-size-16">
        <button class="inner_btn px-5" @click="event_bus.$emit('reset_to_initial_search')">{{ trans('reset') }}</button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
  import {mapState} from "vuex";
  import DatiSoggiorno from "./riepilogo_pagamento/DatiSoggiorno";
  import DatiPrenotazione from "./riepilogo_pagamento/DatiPrenotazione";
  import Basket from "./Basket";

  export default {
    name: "RiepilogoPagamento",
    components: {
      DatiPrenotazione,
      DatiSoggiorno,
      Basket
    },
    data:function(){
      return{
        condizioni_generali_check: false
      }
    },
    computed: {
      ...mapState(['solution_search', 'selected_solution', 'user_contact_data', 'general_conditions_url'])
    },
    methods: {
      replace_condizioni_url(text) {
        console.log(text)
        return text.replace("replace_condizioni_uri", this.general_conditions_url);
      }
    }
  }
</script>

