import _ from 'lodash'

function vehicle_type_options(translater) {

  const vehicle_types = {"roulotte":100,"tent":200,"trailer_awning":300,"camper":400};

  const vehicle_types_options_values = _.sortBy(_.map(vehicle_types, (v, k) => {
    return {
      value: v,
      text: translater(`vue.VehicleData.vehicle_type.values.${k}`)
    };
  }), ["text"])

  vehicle_types_options_values.unshift({
    value: null,
    text: translater("vue.VehicleData.vehicle_type.values.blank")
  });

  return vehicle_types_options_values;
}


export default vehicle_type_options;