<style scoped>
.cards{
  width: 10rem;
}
</style>

<template>
  <b-row>
    <b-col>
      <div class="text-center">
        <div class="pt-5">
          {{trans('text')}}
        </div>
        <div class="pb-5">
          <div class="link orange_btn font-size-14 color-white mt-lg-5 py-3 py-lg-0 text-center-mob">
            <a ref="link_redirect" :href="redirect_url">{{trans('button_label')}}</a>
          </div>
          <div class="py-3">
            <img class="cards" :src="images.cards" alt="">
          </div>
        </div>
      </div>

    </b-col>
  </b-row>
</template>

<script>
  export default {
    name: "PagamentoStarted",
    props: {
      redirect_url: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        images: {
          cards: require('images/carte-credito.png')
        },
      }
    }
  }
</script>

