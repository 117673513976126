<style scoped lang="scss">
  .brd-crmb {
    color: #838383;
    text-align: center;
    padding: 1rem 10px;
    font-weight: bold;
    display: flex;
    align-items: center;
    overflow-y: hidden;
    /*background-color: #EAF8FF;*/
    position: relative;

  .inner-brd{
     display: flex;
     align-items: center;
     position: relative;
     z-index: 2;
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: calc(100% + 0.75rem);
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: {
      image: url("~images/normal_brd.svg");
      size: cover;
      repeat: no-repeat;
      position: right;
    }
  }

  &:last-child{
    &:before {
      background-image: none;
     }
    &.active {
      &:before {
         background-image: none;
        background-color: #74B102;
       }
     }
  }



  .number {
    display: inline-block;
    border: solid 2px #74B102;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text {
    width: calc(100% - 2.5rem);
    display: inline-block;
    padding-left: 0.5rem;
    text-align: center;
  }

  }

  .brd-crmb.active {
    &:before {
      background-image: url("~images/active_brd.svg");
     }
    color: #FFFFFF;
    .number {
      border-color: #FFFFFF;
    }
  }

  .brd-crmb.bread_crumbs_cliccable{
  cursor: pointer;
    &:before {
       background-image: url("~images/cliccable_brd.svg");
     }
  color: #FFFFFF;
  .number {
    border-color: #FFFFFF;
  }
  }



  .brd-minus-ml {
    margin-left: -2rem;
    padding-left: 3rem;
  }

  .brd-1 {
    z-index: 6;
    padding-left: 4rem;
  }

  .brd-2 {
    z-index: 5;
  }

  .brd-3 {
    z-index: 4;
  }

  .brd-4 {
    z-index: 3;
  }

  .brd-5 {
    z-index: 2;
  }

  .brd-6 {
    z-index: 1;
  }
  .brd-crmbs{
    overflow: hidden;
    border: solid 4px #FFFFFF;
    background-color: #EAF8FF;
  }
.bread_crumbs_cliccable{

}
  .round_back_btn{
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
  }
</style>

<template>
  <div>
    <b-row class="brd-crmbs d-none d-lg-flex">
      <b-col class="px-0">
        <b-row class="w-100 m-0">
          <b-col :class="{'active':step=='ricerca','bread_crumbs_cliccable':cliccable('ricerca')}"
                 class="font-size-18 brd-crmb brd-1 brd-minus-ml"
                 @click="go_to_step('ricerca')">
            <div class="inner-brd">
              <span class="number">1</span> <span class="text">{{ trans('ricerca') }}</span>
            </div>
          </b-col>
          <b-col :class="{'active':step=='soluzione_prezzi','bread_crumbs_cliccable':cliccable('soluzione_prezzi')}"
                 class="font-size-18 brd-crmb brd-minus-ml brd-2"
                 @click="go_to_step('soluzione_prezzi')">
            <div class="inner-brd">
              <span class="number">2</span> <span class="text">{{ trans('soluzione_prezzi') }}</span>
            </div>
          </b-col>
          <b-col :class="{'active':step=='servizi_extra','bread_crumbs_cliccable':cliccable('servizi_extra')}"
                 class="font-size-18 brd-crmb brd-minus-ml brd-3"
                 @click="go_to_step('servizi_extra')">
            <div class="inner-brd">
              <span class="number">3</span> <span class="text">{{ trans('servizi_extra') }}</span>
            </div>
          </b-col>
          <b-col :class="{'active':step=='dati_di_contatto','bread_crumbs_cliccable':cliccable('dati_di_contatto')}"
                 class="font-size-18 brd-crmb brd-minus-ml brd-4"
                 @click="go_to_step('dati_di_contatto')">
            <div class="inner-brd">
              <span class="number">4</span> <span class="text">{{ trans('dati_di_contatto') }}</span>
            </div>
          </b-col>
          <b-col
              :class="{'active':step=='riepilogo_pagamento','bread_crumbs_cliccable':cliccable('riepilogo_pagamento')}"
              class="font-size-18 brd-crmb brd-minus-ml brd-5"
              @click="go_to_step('riepilogo_pagamento')">
            <div class="inner-brd">
              <span class="number">5</span> <span class="text">{{ trans('riepilogo_pagamento') }}</span>
            </div>
          </b-col>
          <b-col :class="{'active':(step=='conferma' || step=='payment_verification_failed')}"
                 class="font-size-18 brd-crmb brd-minus-ml brd-6">
            <div class="inner-brd">
              <span class="number">6</span> <span class="text">{{ trans('conferma') }}</span>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row :class="{can_go_back:go_back_destination(step)}" class="d-lg-none back-azure">
      <div class="col-12 px-3">
        <div class="p-3 d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <div class="font-size-14 color-0">
              <div>STEP</div>
              <div class="font-weight-bold">
                {{ actual_position }}/5
              </div>
            </div>
            <div class="font-size-16 color-light-grey px-3 text-uppercase">
              {{ trans(step) }}
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-end">
            <button v-if="go_back_destination(step)" @click="go_back" class="round_back_btn back-col-2 color-white"><</button>
          </div>
        </div>
      </div>


    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    step: {}
  },
  name: "BreadCrumbs",
  computed: {
    actual_position() {
      const posizioni = {
        payment_verification_failed: 7,
        conferma: 6,
        riepilogo_pagamento: 5,
        dati_di_contatto: 4,
        servizi_extra: 3,
        soluzione_prezzi: 2,
        ricerca: 1
      }
      return posizioni[this.step];
    }
  },
  methods: {
    /**
     * Ritorna lo step dove dobbiamo andare
     * @param actual_step
     */
    go_back_destination(actual_step) {
      const posizioni = {
        payment_verification_failed: null,
        conferma: null,
        riepilogo_pagamento: "dati_di_contatto",
        dati_di_contatto: "soluzione_prezzi",
        soluzione_prezzi: "ricerca",
        ricerca: null
      }
      return posizioni[actual_step];
    },
    go_back() {
      console.log(this.step, this.go_back_destination(this.step));
      this.go_to_step(this.go_back_destination(this.step));
    },
    cliccable(required_step) {
      const posizioni = {
        payment_verification_failed: 7,
        conferma: 6,
        riepilogo_pagamento: 5,
        dati_di_contatto: 4,
        servizi_extra: 3,
        soluzione_prezzi: 2,
        ricerca: 1
      }
      return posizioni[required_step] < posizioni[this.step];
    },
    go_to_step(required_step) {
      // controlliamo se possiamo spostarci
      if (this.cliccable(required_step)) {
        this.$emit('move_to', required_step);
      }
    }
  }
}
</script>

