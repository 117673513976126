<style scoped>

</style>

<template>
  <b-row>
    <b-col md="12">
      <HeaderSoluzionePrezzi
          :data_arrivo="solution_search.data_range.checkin"
          :data_partenza="solution_search.data_range.checkout"
          :sistemazione="solution_search.sistemazione_human"
          :occupanti="solution_search.n_pax"
          :eta_partecipanti="solution_search.pax_ages"
          @reset="event_bus.$emit('reset_to_initial_search')"></HeaderSoluzionePrezzi>
    </b-col>


    <b-col md="12">
      <BaseContactsForm :form_submit_url="submit_url()"
                        :submit_button_text="trans('procedi')"
                        :more_data="{ vehicle_model: null,      vehicle_dimensions: null,      vehicle_type: null}"
                        @submitted="event_bus.$emit('request_info_generic_submitted',arguments[0])">

        <template v-slot:prepend="slotData">
          <template v-if="solution_search.solutions_category===3">
            <b-col md="12">
              <h3>Dati Generali</h3>
            </b-col>
            <b-row class="justify-content-center">
              <b-col lg="8">
            <VehicleData :form.sync="slotData.form"
                             :errors="slotData.errors"
                             :show_errors="slotData.show_errors"></VehicleData>
              </b-col>
            </b-row>
          </template>

          <b-col md="12">
            <h3>Richiesta Informazioni</h3>
          </b-col>
        </template>

      </BaseContactsForm>
    </b-col>
  </b-row>
</template>

<script>
  import {mapState} from "vuex";
  import BaseContactsForm from "../BaseContactsForm";
  import HeaderSoluzionePrezzi from "./soluzione_prezzi/HeaderSoluzionePrezzi";
  import VehicleData from "../VehicleData";

  export default {
    name: "RichiestaGenerica",
    components:{
      BaseContactsForm,
      HeaderSoluzionePrezzi,
      VehicleData
    },
    computed: {
      ...mapState(['solution_search'])
    },
    methods: {
      submit_url() {
        return Routes.generic_info_request_bookings_sessions_path;
      }
    }
  }
</script>

