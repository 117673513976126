<style scoped>

</style>

<template>
  <b-row>
    <b-col>
      <div class="image image-w-100 pt-5 pt-lg-0">
        <img :src="selected_pack.pack_exposer.image">
      </div>
      <div class="title py-3 font-weight-normal font-size-30 color-2">
        {{ selected_pack.pack_exposer.title }}
      </div>
      <div v-html="selected_pack.pack_exposer.description" class="description font-size-12 pb-3 line-h-2">
      </div>

      <div class="font-size-14">
      <div v-if="selected_pack.pack_exposer.valid_from" class="row m-0 py-lg-1 py-2 riga_info">
        <div class="col-lg-4 px-0  font-weight-bold">
          {{ $t('validity_period') }}
        </div>
        <div class="col-lg-8 px-0 grey-mob">
          {{ $t('from') }} {{ selected_pack.pack_exposer.valid_from }} {{ $t('to') }}   {{ selected_pack.pack_exposer.valid_to }}
        </div>
      </div>
      <div v-if="selected_pack.pack_exposer.duration" class="row m-0 py-lg-1 py-2 riga_info">
        <div class="col-lg-4 px-0  font-weight-bold">
          {{ $t('duration') }}
        </div>
        <div class="col-lg-8 px-0 grey-mob">
          {{ selected_pack.pack_exposer.duration }}
        </div>
      </div>
      </div>


    </b-col>
  </b-row>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "PackInfo",
  computed: {
    ...mapState(['selected_pack'])
  }
}
</script>

