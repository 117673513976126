<style scoped>
  .icon-col {
    background-color: #B40606;
  }
</style>

<template>

  <b-row>
    <b-col md="12">

      <b-row>
        <b-col lg="1" class="icon-col py-3">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="60.5" height="60.5" viewBox="0 0 60.5 60.5">
              <path id="Path_599" data-name="Path 599" d="M34.25,64.5h0A30.25,30.25,0,1,1,64.5,34.25,30.249,30.249,0,0,1,34.25,64.5Zm0-2.42A27.83,27.83,0,1,0,6.42,34.25,27.831,27.831,0,0,0,34.25,62.08ZM34.2,45.14A19.713,19.713,0,0,0,20.94,49.98V47.56A19.713,19.713,0,0,1,34.2,42.72,20.1,20.1,0,0,1,47.56,47.56v2.42A20.091,20.091,0,0,0,34.2,45.14Zm10.021-26.7-1.353,2.006L50.9,25.86l1.353-2.006ZM16.25,23.854,17.6,25.86l8.025-5.414L24.275,18.44Zm7.11,6.766a2.42,2.42,0,1,0-2.42-2.42,2.421,2.421,0,0,0,2.42,2.42Zm21.78,0a2.42,2.42,0,1,0-2.42-2.42,2.421,2.421,0,0,0,2.42,2.42Z" transform="translate(-4 -4)" fill="#fff"/>
            </svg>
          </div>
        </b-col>

        <b-col>

          <div class="font-size-30 color-1 font-weight-bold">
            {{trans('titolo')}}
          </div>
          <div class="font-size-24 line-h-2 color-1 pb-3" v-html=" trans('testo')"></div>

          <b-row>
            <b-col lg="4">


              <div class="d-flex mx-0 align-items-center py-2">
                <div class="font-size-14 label_data_top color-1">{{trans("name")}}</div>
                <div class="px-3">
                <span class="font-size-24">
                  {{ name }}
                </span>
                </div>
              </div>

              <div class="d-flex mx-0 align-items-center py-2">
                <div class="font-size-14 label_data_top color-1">{{trans("surname")}}</div>
                <div class="px-3">
                <span class="font-size-24">
                  {{ surname }}
                </span>
                </div>
              </div>

              <div class="d-flex mx-0 align-items-center py-2">
                <div class="font-size-14 label_data_top color-1">{{trans("email")}}</div>
                <div class="px-3">
                <span class="font-size-24">
                  {{ email }}
                </span>
                </div>
              </div>

              <div class="d-flex mx-0 align-items-center py-2">
                <div class="font-size-14 label_data_top color-1">{{trans("date_of_stay")}}</div>
                <div class="px-3">
                <span class="font-size-24">
                  {{new Date(data_arrivo) | dateFormat('DD/MM')}} -  {{new Date(data_partenza) | dateFormat('DD/MM')}}
                </span>
                </div>
              </div>

              <div class="d-flex mx-0 align-items-center py-2">
                <div class="font-size-14 label_data_top color-1">{{trans("sistemazione")}}</div>
                <div class="px-3">
                <span class="font-size-24">
                  {{ sistemazione }}
                </span>
                </div>
              </div>


            </b-col>

            <b-col lg="4">

              <b-col>
                <b-form @submit="onSubmit">

                  <b-form-group
                     :invalid-feedback="mess_feedback"
                     :state="mess_state">
                    <b-form-textarea v-model="form.mess"
                                     :state="mess_state"
                                     :placeholder="trans('mess.label')"
                                     rows="3"
                                     max-rows="6"
                    />
                  </b-form-group>
                  <b-col md="12" class="text-center py-3">
                    <div class="orange_btn color-white font-size-14">
                      <button type="submit" class="inner_btn px-5">{{trans('submit_help_request')}}</button>
                    </div>
                  </b-col>
                </b-form>


              </b-col>

            </b-col>
          </b-row>


        </b-col>
      </b-row>
    </b-col>


  </b-row>


</template>

<script>

  import Vue from 'vue';
  import {build_StateFeedback, setAxiosCSRF} from "../../../helpers";

  const axios = setAxiosCSRF();
  export default {
    name: "Failure",
    props: [
      'name',
      'surname',
      'email',
      'data_arrivo',
      'data_partenza',
      'sistemazione'
    ],
    data() {
      return {
        informativa_ev: new Vue(),
        form: {
          mess: ''
        },
        errors: {},
        show_errors: false
      }
    },
    computed: {
      ...build_StateFeedback(
        'mess'
      )
    },
    methods: {
      onSubmit(e) {
        e.preventDefault();
        axios.post(Routes.send_help_request_failure_bookings_sessions_path({
          format: 'json',
          locale: window.current_locale
        }), {form: this.form}).then((request) => {

          if (request.data.success) {
            this.show_errors = false;
            this.$emit('submitted', this.form);
          } else {
            this.show_errors = true;
            this.errors = Object.assign({}, request.data.errors);
          }

        });
      }
    }
  }
</script>

