import _ from 'lodash'
import {setAxiosCSRF} from "./helpers";

const axios = setAxiosCSRF();

function update_datas(state, payload) {
  _.each({...state, ...payload}, (val, index) => {
    state[index] = val
  })
}

//
// function b64DecodeUnicode(str) {
//   // Going backwards: from bytestream, to percent-encoding, to original string.
//   return decodeURIComponent(atob(str).split('').map(function(c) {
//     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//   }).join(''));
// }
//
// export function reset_vuex_session_store() {
//   window.sessionStorage.removeItem('vuex');
// }
//
// /**
//  * Formatta a DATE le date del from e del to all'interno dell'oggetto passato.
//  * Viene ritornato un nuovo oggetto clone del precedente
//  */
// export function convert_datetime_to_date_from_to(select_date_data) {
//
//
//   let c = Object.assign({}, select_date_data);
//
//   c.from = moment(select_date_data.from).format('YYYY-MM-DD');
//   c.to = moment(select_date_data.to).format('YYYY-MM-DD');
//
//   return c;
//
// }

function store_generator(vuex) {

  let store = new vuex.Store({
    // plugins: [createPersistedState({storage: window.sessionStorage})],
    state: {

      /**
       * Indica il loading globale del sistema booking, a true caricherà un layer globale
       */
      global_loading: false,
      /**
       * Contatore del numero di loadings presenti contemporaneamente
       */
      ajax_loadings: 0,

      /**
       * Lingua di sistema, default: en
       */
      locale: 'en',

      /**
       * Indica lo status all'interno del booking, lo riceviamo da rails
       */
      booking_step: null,

      /**
       * Oggetto contenente l'ultima ricerca effettuata
       */
      solution_search: {},

      /**
       * Soluzione selezionata
       */
      selected_solution: {},

      /**
       * Informazioni per gli accessori e extra disponibili
       */
      available_accessories: [],
      available_extras: [],

      /**
       * Paccketto selezionato
       */
      selected_pack: {},

      /**
       * Dati di contatto
       */
      user_contact_data: {},

      /**
       * Url di redirect inviatoci dalla banca
       */
      bank_redirect_url: null,

      /**
       * Redirect url per il booking quando, dopo la notifica della banca, torniamo sull'interfaccia booking
       */
      redirect_url: null,

      /**
       * Configurazioni per la pagina della policy delle form
       */
      privacy_policy_text: '',
      privacy_policy_url: '',


      admin_notification_email: '',
      general_conditions_text: '',
      general_conditions_url: '',

      form_description_text: '',
      form_description_title: '',


      //
      // /**
      //  * Testo di conferma post invio messaggio di conferma richiesta alternativa
      //  */
      // confirmation_send_request: ''
    },
    mutations: {
      update_datas,
      set_redirect_url(state, payload) {
        state.redirect_url = payload;
      },
      set_admin_notification_email(state, payload) {
        state.admin_notification_email = payload;
      },
      set_general_conditions_text(state, payload) {
        state.general_conditions_text = payload;
      },
      set_form_description_text(state, payload) {
        state.form_description_text = payload;
      },
      set_form_description_title(state, payload) {
        state.form_description_title = payload;
      },
      set_general_conditions_url(state, payload) {
        state.general_conditions_url = payload;
      },
      set_ajax_loadings(state, payload) {
        state.ajax_loadings = payload;
      },
      set_global_loading(state, payload) {
        state.global_loading = payload;
      }
    },
    actions: {
      increase_ajax_counts({commit, state: {ajax_loadings, global_loading}}) {
        commit('set_ajax_loadings', ajax_loadings + 1);
        commit('set_global_loading', true);
      },
      decrease_ajax_counts({commit, state: {ajax_loadings, global_loading}}) {
        let contatore = ajax_loadings - 1;
        commit('set_ajax_loadings', contatore);
        if (contatore == 0) {
          commit('set_global_loading', false);
        }
      },
      change_step({
                    commit,
                    state: {
                      redirect_url,
                      admin_notification_email,
                      general_conditions_text,
                      general_conditions_url
                    }
                  }, step) {
        axios.post(Routes.change_step_bookings_sessions_path({format: "json", locale: window.current_locale}), {
          step,
          redirect_url,
          admin_notification_email,
          general_conditions_text,
          general_conditions_url
        }).then((response) => {
          commit('update_datas', response.data)
        });
      },
      solution_selected({commit}, solution) {
        return new Promise((res, reje) => {
          axios.post(Routes.selected_solution_bookings_sessions_path({format:"json", locale: window.current_locale}), {solution}).then((response) => {
            commit('update_datas', response.data);
            res();
          }).catch(reje);
        })
      },
      append_extra({commit}, {code, sgs_type, qnt}) {
        return new Promise((res, reje) => {
          axios.post(Routes.append_extra_service_bookings_sessions_path({
            format: "json",
            locale: window.current_locale
          }), {code, sgs_type, qnt}).then((response) => {
            commit('update_datas', response.data);
            res();
          }).catch(reje);
        })
      },
      remove_extra({commit}, {code}) {
        return new Promise((res, reje) => {
          axios.post(Routes.remove_extra_service_bookings_sessions_path({
            format: "json",
            locale: window.current_locale
          }), {code}).then((response) => {
            commit('update_datas', response.data);
            res();
          }).catch(reje);
        })
      },
      reset_booking_process({dispatch}) {
        return new Promise((res, reje) => {
          axios.delete(Routes.bookings_sessions_path()).then((response) => {
            dispatch('change_step', 'ricerca').then((ris) => {
              res();
            }).catch(reje);
          }).catch(reje);
        })
      },
      start_payment_process({commit}) {
        return new Promise((res, reje) => {
          axios.post(Routes.start_payment_process_bookings_sessions_path()).then((response) => {
            commit('update_datas', response.data);
            res(response.data);
          }).catch(reje);
        })
      }
    }
  });

  axios.interceptors.request.use(function (config) {
    if(!config.disableGlobalLoading){
      store.dispatch('increase_ajax_counts')
    }
    return config;
  }, function (error) {
    store.dispatch('decrease_ajax_counts')
    return Promise.reject(error);
  });

  axios.interceptors.response.use(function (response) {
    if(!response.config.disableGlobalLoading) {
      store.dispatch('decrease_ajax_counts')
    }
    return response;
  }, function (error) {
    store.dispatch('decrease_ajax_counts')
    return Promise.reject(error);
  });

  axios.get(Routes.bookings_sessions_path({format:"json", locale: window.current_locale})).then((response) => {
    store.commit('update_datas', response.data);
  });

  return store;
}

export default store_generator;
