export default {
  install: function (Vue, options) {

    /**
     * Passare un oggetto da spedire come dizionario di variabili
     * @param vars
     */
    Vue.dt_variables = function (vars) {
      console.log('setVariables', vars);
      window.dataLayer.push(vars)
    }


    let previus_step = null;
    /**
     * Viene cambiato l'evento con lo step, solamente se veramente cambiamo step.
     * @param step_name
     */
    Vue.dt_change_step = function (step_name) {
      if (previus_step != step_name) {
        console.log('ChangeStep', step_name);
        window.dataLayer.push({'event': 'ChangeStep', step_name});
        previus_step = step_name
      }
    }

  }
}
