<style scoped lang="scss">

$background-white: #EAF8FF;

.box_input_filter {
  background-color: $background-white;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;

  select, input {
    background-color: transparent;
    border: none;
    border-radius: 0;
    height: 100%;
  }

}

.label_input_filter {
  background-color: $background-white;
  padding: 0.5rem;
  text-transform: uppercase;
}

.solution-container {

  $blue: #01628D;

  background-color: $background-white;
  padding: 0.5rem;
  cursor: pointer;

  .title{
    color: $blue;
    text-align: center;
  }

  &.selected {
   border: 2px dashed $blue;
  }
}


</style>

<style>

legend {
  color: #01628D;
  font-size: 0.7rem;
}

.transparent-labels legend {
  color: transparent;
}
</style>

<template>
  <div>
    <b-row>
      <b-col :md="is_in_pack ? 12 : 4">
        <b-form-group
          :invalid-feedback="data_range_feedback"
          :state="data_range_state"
          :label="trans('data_range.label')"
        >
          <div class="label_input_filter font-size-14 color-0">
            {{ trans('input.datepicker.label') }}
          </div>
          <div class="box_input_filter left_0 px-0">
            <date_picker v-model="internal_data.data_range"></date_picker>
          </div>
        </b-form-group>
      </b-col>
      <b-col :md="is_in_pack ? 12 : 2">
        <b-form-group
          :invalid-feedback="n_pax_feedback"
          :state="n_pax_state"
          :label="trans('n_pax.label')"
        >
          <div class="label_input_filter font-size-14 color-0">
            {{ trans('input.n_pax.label') }}
          </div>
          <div class="box_input_filter">
            <b-form-input v-model="internal_data.n_pax"
                          :formatter="max_npax" placeholder="0"></b-form-input>
          </div>

        </b-form-group>
      </b-col>
      <b-col :md="is_in_pack ? 12 : 6" class="px-0">
        <b-row class="m-0 text-center transparent-labels">
          <b-col md="4" v-for="(eta,index) in internal_data.pax_ages">
            <b-form-group
              label="_"
              :invalid-feedback="pax_ages_feedback"
              :state="pax_ages_state"
            >
              <div class="label_input_filter font-size-14 color-0">
                {{ trans('input.pax_ages.label') }} {{ index + 1 }}
              </div>
              <div class="box_input_filter">
                <b-form-input type="number" min="1" max="100" @change="change_ages(index,$event)"
                              :value="eta"
                              :formatter="max_age"
                              :key="index"
                              placeholder="-"
                              size="sm"/>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

    </b-row>
    <b-row>
      <b-col :md="is_in_pack ? 12 : 3">

        <b-form-group
          :invalid-feedback="solutions_category_feedback"
          :state="solutions_category_state"
          :label="trans('solution.label')"
        >
          <div class="label_input_filter font-size-14 color-0">
            {{ trans('input.solutions.label') }}
          </div>
          <div class="box_input_filter">
            <b-form-select v-model="internal_data.solutions_category" :options="solutions_categories"></b-form-select>
          </div>
        </b-form-group>
      </b-col>
      <b-col :md="is_in_pack ? 12 : 9" v-if="internal_data.solutions_category" class="transparent-labels">
        <b-form-group
          :invalid-feedback="solution_feedback"
          :state="solution_state"
          label="_"
        >
          <b-row>
            <b-col mb-1 cols="3" v-for="solution in solutions">

              <div :class="{ 'solution-container': true, 'selected':(internal_data.solution==solution.id ? 'selected' : '')}"
                   @click="select_solution(solution)">
                <div class="title mb-1">{{ solution.name }}</div>
                <b-img-lazy fluid :src="solution.thumbnail_desktop.url" :alt="solution.name"></b-img-lazy>
              </div>


            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import date_picker from "../../datepicker/base"
import {build_StateFeedback, setAxiosCSRF} from "../helpers";

const axios = setAxiosCSRF();

const base_data = {
  data_range: {
    checkin: null,
    checkout: null
  },
  solutions_category: null,
  solution:null,
  n_pax: 1,
  pax_ages: []
};
export default {
  name: "SearchFilter",
  components: {
    date_picker
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
      required: true
    },
    /**
     * Devo passare la funzione di Routejs
     */
    form_submit_url: {
      default: function () {
        return Routes.search_bookings_solutions_path;
      }
    },
    is_in_pack: {
      default: false,
      type: Boolean
    }
  },
  data: function () {

    axios.get(Routes.bookings_solutions_categories_path({
      format: "json",
      locale: window.current_locale
    })).then((request) => {
      this.solutions_categories = [{value: null, text: "-", disabled: true}];
      this.solutions_categories = this.solutions_categories.concat(_.map(request.data, (k) => {
        return {value: k.id, text: k.name}
      }));
    });

    let internal = Object.assign(base_data, this.value);

    if(internal.solutions_category){
      this.load_solutions(internal.solutions_category);
    }

    internal.pax_ages = this.recalculate_pax_ages(internal.n_pax, internal.pax_ages);

    return {
      solutions_categories: {},
      solutions: [],
      internal_data: internal,
      errors: {},
      show_errors: false
    }
  },
  computed: {
    ...build_StateFeedback('pax_ages', 'n_pax', 'solutions_category', 'data_range','solution')
  },
  methods: {
    select_solution(solution){
      this.internal_data.solution = solution.id;
    },
    change_ages(index, value) {
      this.internal_data.pax_ages[index] = parseInt(value);
    },
    recalculate_pax_ages(n_pax, pax_ages) {
      let new_list = [];

      for (let x = 0; x < n_pax; x++) {
        new_list[x] = pax_ages[x] || null;
      }

      return new_list;
    },
    max_npax(value) {
      if (value > 30) {
        return 30;
      } else {
        return value;
      }
    },
    max_age(value) {
      if (value > 100) {
        return 100;
      } else {
        return value;
      }
    },
    /**
     * Esegue la ricerca delle soluzioni possibili e restituisce una promise con l'elenco delle soluzioni
     */
    make_search(merged_params = {}) {
      return new Promise((resolve, reject) => {

        let dati = Object.assign({solution_search: this.internal_data}, merged_params)

        axios.post(this.form_submit_url({format: "json", locale: window.current_locale}), dati).then((request) => {

          if (request.data.success) {
            resolve(request.data.results);
            this.show_errors = false;
          } else {
            this.show_errors = true;
            this.errors = request.data.errors;
          }

        }).catch(reject);
      })

    },
    load_solutions(v){
      axios.get(Routes.bookings_solutions_category_solutions_path({
        format: "json",
        locale: window.current_locale,
        solutions_category_id: v
      })).then((request) => {
        this.solutions = request.data;
      });
    }
  },
  watch: {
    "internal_data.n_pax": function (v) {
      this.internal_data.pax_ages = this.recalculate_pax_ages(v, this.internal_data.pax_ages);
    },
    "internal_data.solutions_category": function (v) {
    this.load_solutions(v)
    }
  }

}
</script>

