<style scoped>

</style>

<template>
  <b-row>
    <b-col>
      <SearchFilter v-model="internal_data" ref="search_filter"></SearchFilter>
      <b-row>
        <b-col class="text-center-mob">
          <div class="orange_btn color-white font-size-14 d-inline-block my-3 my-lg-0">
            <button @click="search_solutions" class="inner_btn">
              {{trans('verifica_disponibilita')}}
            </button>
          </div>
          <div class="grey_btn color-white font-size-14 d-inline-block">
            <button @click="go_to_generic_request" class="inner_btn">
              {{trans('richiesta_generica')}}
            </button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h3 class="pt-4 pb-2 mb-0 color-2 bold-font">{{$store.state.form_description_title}}</h3>
          <div v-html="$store.state.form_description_text"></div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>

  import SearchFilter from "../SearchFilter";

  export default {
    components: {
      SearchFilter
    },
    name: "Ricerca",
    props: {
      value: {
        type: Object,
        default() {
          return {}
        },
        required: true
      }
    },
    data: function () {
      return {
        internal_data: Object.assign({}, this.value)
      }
    },
    methods: {
      search_solutions() {
        this.$refs.search_filter.make_search().then((soluzioni) => {
          this.event_bus.$emit('search_results', soluzioni);
        })
      },
      go_to_generic_request() {
        this.$refs.search_filter.make_search().then(() => {
          this.event_bus.$emit('generic_request');
        })

      }
    }


  }
</script>

