<style scoped lang="scss">
.cursor_pointer {
  cursor: pointer;
}

</style>

<template>
  <div>
    <div class="orange_btn pt-3 text-center color-white font-size-14">
      <a class="cursor_pointer" v-b-modal.pack_modal_form>
        {{ $t("vue.PackModal.request_information") }}
      </a>
    </div>

    <b-modal id="pack_modal_form" class="modal_with_back modal_pacchetti" hide-header hide-footer centered>
      <button type="button" class="button-close"  @click="$bvModal.hide('pack_modal_form')">
       &times;
      </button>
      <b-container fluid>
        <b-row>
          <b-col md="12">
            <SearchFilter v-model="internal_data" ref="search_filter" :is_in_pack="true" :form_submit_url="form_path()"></SearchFilter>
          </b-col>
          <b-col>
            <div class="orange_btn pt-3 text-center color-white font-size-14">
              <a class="cursor_pointer" @click.prevent="require_packet">
                {{ $t("vue.PackModal.request_information") }}
              </a>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

  </div>

</template>

<script>

import SearchFilter from "../booking/components/SearchFilter";

export default {
  name: "PackModal",
  components: {
    SearchFilter
  },
  data: function () {
    return {
      internal_data: {},
      pack_id: this.$root.pack_id,
      booking_url: this.$root.booking_url
    }
  },
  methods: {
    form_path() {
      return Routes.selected_pack_bookings_sessions_path;
    },
    require_packet() {
      this.$refs.search_filter.make_search({selected_pack: {id: this.pack_id}}).then(() => {
        window.location.href = this.booking_url;
      })
    }
  }
}
</script>

