<style scoped>

</style>

<template>
  <b-row>
    <b-col md="7">

      <HeaderSoluzionePrezzi
         :data_arrivo="solution_search.data_range.checkin"
         :data_partenza="solution_search.data_range.checkout"
         :sistemazione="solution_search.sistemazione_human"
         :occupanti="solution_search.n_pax"
         :eta_partecipanti="solution_search.pax_ages"
         @reset="event_bus.$emit('reset_to_initial_search')"></HeaderSoluzionePrezzi>

<div class="d-sm-none py-3">
  <Basket></Basket>
</div>
      <ExtraServices></ExtraServices>
      <VehicleDataForm ref="vehicle_data_form" v-if="is_piazzola"></VehicleDataForm>

      <div class="pt-5">
        <div  class="orange_btn color-white font-size-12 p-2 w-100 text-center">
          <button class="inner_btn" @click="proceed">{{ trans('procedi') }}</button>
          <div class="font-size-12 color-1">
            {{ trans('dati') }}
          </div>
        </div>
      </div>



    </b-col>
    <b-col md="5" class="d-none d-sm-block">

      <Basket></Basket>


    </b-col>
  </b-row>
</template>

<script>
  import HeaderSoluzionePrezzi from "./soluzione_prezzi/HeaderSoluzionePrezzi";
  import Basket from "./Basket";
  import ExtraServices from "./servizi_extra/ExtraServices";
  import VehicleDataForm from "./servizi_extra/VehicleDataForm";
  import {mapState} from "vuex";

  export default {
    name: "ServiziExtra",
    components: {
      HeaderSoluzionePrezzi,
      Basket,
      ExtraServices,
      VehicleDataForm
    },
    computed: {
      ...mapState(['solution_search']),
      is_piazzola(){
        return this.solution_search.solutions_category===3;
      }
    },
    methods: {
      proceed() {
        if (this.is_piazzola) {
          this.$refs.vehicle_data_form.submit().then((request) => {
            if (request.data.success) {
              this.event_bus.$emit('to_contact_datas')
            }
          })
        } else {
          this.event_bus.$emit('to_contact_datas')
        }
      }
    }
  }
</script>

