<style scoped>
  .color-price {
    color: #475F7B;
  }

  @media (min-width: 992px) {
    .minus-m-title {
      margin-left: -5rem;
      margin-right: 5rem;
      width: calc(100% + 10rem);
    }
  }


  .border-top-black {
    border-top: dashed 1px #000000;
  }

  .barrato {
    text-decoration: line-through;
  }

  .better_price {
    background-color: #74B102;
    color: #fff;
  }

  .back_light{
    background-color:rgba(234,248,255,0.3);
  }
</style>

<style scoped lang="scss">
.title-bar {
  .solution-name {
    z-index: 1;
  }
}

</style>

<style lang="scss">
@import '~stylesheets/_variables.scss';

.container_gallery {
  .owl-dots {
    position: absolute;
    bottom: 0;
    width: 100%;
    .owl-dot{
      &.active{
        span{
          background: $col_2;
        }
      }
    }
  }
}
</style>

<template>
  <div>
  <b-row class="riga back-white mb-3">
    <div class="d-flex flex-wrap d-lg-none minus-m-title w-100">
      <div class="col-6 color-white back-col-2 font-size-16-mob font-size-30 pl-lg-2 pr-lg-5">
        {{solution.name}}
      </div>
      <div class="col-6 d-flex px-0">
        <div :class="{barrato:solution.totale>solution.totale_trattamento}"
             class="back-col-10 font-size-14 px-3 color-price d-flex align-items-center justify-content-center">
          {{solution.totale | currency}}
        </div>
        <div v-if="solution.totale>solution.totale_trattamento"
             class="back-col-10 font-size-14 px-3 color-price d-flex align-items-center justify-content-center better_price">
          {{solution.totale_trattamento | currency}}
        </div>
      </div>
    </div>
    <div class="d-flex flex-wrap d-lg-none minus-m-title w-100 justify-content-end ">
      <div
         class="color-white position-absolute z-2 col-6 px-0 py-1 back-grey font-size-16-mob font-size-30 pl-lg-2 pr-lg-5">
        <div class="d-inline font-size-24 font-14-mob px-3">
          {{ new Date(solution.solution_identification.from) | dateFormat('DD/MM') }} - {{ new
          Date(solution.solution_identification.to) | dateFormat('DD/MM') }} <span v-if="show_nights"> | <span
           class="font-12-mob">{{ trans('nights') }}</span> {{ nights }}</span> <span></span>
        </div>
      </div>
    </div>
    <b-col lg="4" class="px-0">
      <div class="container_gallery">
        <carousel :autoplay="false" :items="1" :dots="true" :loop="true" :nav="false">
          <img v-for="picture in solution.gallery" :src="picture">
        </carousel>
      </div>
    </b-col>
    <b-col lg="6" class="py-2">
      <div class="pb-3 d-none d-lg-flex minus-m-title w-top-bar title-bar">
        <div class="color-white back-col-2 font-size-16-mob font-size-30 pl-lg-2 pr-lg-3 solution-name" >
          {{solution.name}}
        </div>
        <div class="color-white back-grey font-size-16-mob font-size-30 pl-lg-2 pr-lg-3">
          <div class="d-inline font-size-16 px-2">
            {{trans("period")}}
          </div>
          <div class="d-inline font-size-24">
            {{ new Date(solution.solution_identification.from) | dateFormat('DD/MM') }} - {{ new
            Date(solution.solution_identification.to) | dateFormat('DD/MM') }} <span v-if="show_nights"> | <span
             class="">{{ trans('nights') }}</span>  <span class="font-weight-bold">{{ nights }}</span></span>
          </div>
        </div>
        <div :class="{barrato:solution.totale>solution.totale_trattamento}"
             class="back-col-10 font-size-24 px-3 color-price d-flex align-items-center justify-content-center">
          {{solution.totale | currency}}
        </div>
        <div v-if="solution.totale>solution.totale_trattamento"
             class="3 back-col-10 font-size-24 px-3 color-price d-flex align-items-center justify-content-center better_price">
          {{solution.totale_trattamento | currency}}
        </div>
      </div>


      <b-row class="d-lg-none">
        <b-col lg="3">
          <div class="m-0 d-flex icon-text-solution">

            <div class="d-flex align-items-center py-2 pr-3">
              <div class="icon">
                <img class="icon" :src="images.persons"/>
              </div>
              <div class="text px-2 font-size-12 color-0">
                {{solution.persons_number}}
              </div>
            </div>

            <div class="d-flex align-items-center py-2">
              <div class="icon">
                <img class="icon" :src="images.space"/>
              </div>
              <div class="text px-2 font-size-12 color-0">
                {{solution.dimension}}
              </div>
            </div>
          </div>
        </b-col>


        <b-col lg="6" class="pt-2">

          <div
             class="btn btn-primary w-100 text-left back-col-1 font-size-16 border-radius-7 d-flex justify-content-between align-items-center collapse_cont collapsed  position-relative z-2"
             :aria-controls="'collapse_domanda_'+ solution.id" data-toggle="collapse"
             :data-target="'#collapse_domanda_'+ solution.id" aria-expanded="false">
            <div class="title">
              {{trans("more_info")}}
            </div>
            <div class="back-col-2 border-radius-7 py-2 d-inline-block icon-collapse">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="21" height="18" viewBox="0 0 21 18">
                <path id="Polygon_6" data-name="Polygon 6" d="M10.5,0,21,18H0Z"
                      transform="translate(21 18) rotate(180)" fill="#fff"/>
              </svg>
            </div>
          </div>
          <div class="collapse" :id="'collapse_domanda_'+ solution.id">

            <div class="font-size-14 label_data_top color-1 pb-2">
              {{trans("prezzo")}}
            </div>


            <div v-for="l in normal_list" :key="l.descrizione">
              <b-row class="pb-1">
                <b-col>
                  {{l.qta}} x {{l.descrizione}}
                </b-col>
                <b-col class="text-right">
                  {{l.netto | currency}}
                </b-col>
              </b-row>
            </div>

            <b-row class="pb-1">
              <b-col class="border-top-black  pt-1">
                <strong>{{trans('totale')}}</strong>
              </b-col>
              <b-col class="border-top-black  pt-1 text-right">
                <strong> {{ solution.totale | currency }}</strong>
              </b-col>
            </b-row>

            <b-row class="pb-1">
              <b-col>
                <strong>{{trans('caparra_richiesta')}}</strong>
              </b-col>
              <b-col class="text-right">
                <strong>{{solution.acconto | currency}}</strong>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>


      <b-row class="d-none d-lg-flex">
        <b-col lg="5">
          <div class="m-0 icon-text-solution">

            <div class="d-flex align-items-center py-2">
              <div class="icon">
                <img class="icon" :src="images.persons"/>
              </div>
              <div class="text px-2 font-size-12 color-0">
                {{solution.persons_number}}
              </div>
            </div>

            <div class="d-flex align-items-center py-2">
              <div class="icon">
                <img class="icon" :src="images.space"/>
              </div>
              <div class="text px-2 font-size-12 color-0">
                {{solution.dimension}}
              </div>
            </div>
          </div>
        </b-col >

        <b-col lg="6" class="font-size-14 back_light pt-3">
          <div class="">
            <div class="font-size-14 label_data_top color-1 pb-2">
              <b-row class="pb-1">
                <b-col sm="5">
                  {{trans("prodotto")}}
                </b-col>
                <b-col sm="4">
                  {{trans("quantita")}}
                </b-col>
                <b-col sm="3">
                  {{trans("prezzo")}}
                </b-col>
              </b-row>
            </div>


            <div v-for="l in normal_list" :key="l.descrizione">
              <b-row class="pb-1">
                <b-col sm="5">
                  {{l.descrizione}}
                </b-col>
                <b-col sm="4">
                  {{l.qta}}
                </b-col>
                <b-col sm="3">
                  {{l.netto | currency}}
                </b-col>
              </b-row>
            </div>

            <b-row class="pb-1 color-1">
              <b-col class="border-top-black  pt-1" sm="5">
                <strong>{{trans('totale')}}</strong>
              </b-col>
              <b-col class="border-top-black" sm="4">
              </b-col>
              <b-col class="border-top-black  pt-1" sm="3">
                <strong> {{solution.totale | currency}}</strong>
              </b-col>
            </b-row>

            <b-row class="pb-1 color-1">
              <b-col sm="5">
                <strong> {{trans('caparra_richiesta')}}</strong>
              </b-col>
              <b-col sm="4">
              </b-col>
              <b-col sm="3">
                {{solution.acconto | currency}}
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>

    </b-col>
    <b-col lg="2" class="d-flex flex-column justify-content-center align-items-center">
      <div class="w-dsk-100 orange_btn color-white font-size-14 font-size-16-mob py-3 py-lg-2">
        <button class="col-lg-11 mx-auto inner_btn px-5 px-lg-3" @click="$emit('selected',solution)">
          {{trans('prenota')}}
        </button>
      </div>
      <div class="w-dsk-100 grey_btn color-white font-size-14 font-12-mob pb-lg-2 pb-4 pt-lg-2 pt-5">
        <button class="col-lg-11 mx-auto inner_btn px-2 px-lg-3" @click="$emit('selected_for_information',solution)">
          {{trans('richiedi_informazioni')}}
        </button>
      </div>
    </b-col>
  </b-row>

    <b-row>
      <diffDateSelector v-for="diff_date in solution.range_date_differenti"
                        :diff_date="diff_date"
                        :key="diff_date.idrisorsa+diff_date.from+diff_date.to"
                        @select_different_date="event_bus.$emit('select_different_date',arguments[0])"
      ></diffDateSelector>
    </b-row>
  </div>
</template>

<script>

  import _ from 'lodash';
  import carousel from 'vue-owl-carousel'
  import diffDateSelector from "./DiffDateSelector.vue";

  export default {
    name: "ListElementSolution",
    components: {
      carousel,
      diffDateSelector
    },
    props: {
      solution: {}
    },
    data() {
      return {
        images: {
          persons: require('images/person-icon.svg'),
          space: require('images/space-icon.svg')
        },
        show_nights: true
      }
    },
    computed: {
      promotions() {
        return _.filter(this.solution.listino, {is_promotion: true});
      },
      nights() {
        return this.solution.solution_identification.notti;
      },
      normal_list() {
        return _.filter(this.solution.listino, {is_promotion: false});
      }
    }
  }
</script>

