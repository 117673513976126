<style lang="scss" scoped>


</style>
<style lang="scss">


</style>
<template>
  <div class="booking-content d-flex align-items-center color-white py-2">
    <!--        <div class="py-3">-->
    <!--            booking-->
    <!--        </div>-->
    <template v-if="!loading_calendar">
      <HotelDatePicker ref="date_picker"
          format="DD-MM-YYYY"
          :startingDateValue="internal_data.checkin"
          :endingDateValue="internal_data.checkout"
          :startDate="start_season"
          :endDate="end_season"
          :i18n="pickerTranslations"
          @check-in-changed="checkin"
          @check-out-changed="checkout"></HotelDatePicker>
    </template>
  </div>
</template>
<script>
  import HotelDatePicker from 'vue-hotel-datepicker'
  import moment from 'moment';
  import {setAxiosCSRF} from "../booking/helpers";

  const axios = setAxiosCSRF();

  export default {
    name: 'date_picker',
    props: {
      value: {
        default() {
          return {}
        }
      }
    },
    components: {
      HotelDatePicker,
    },
    data: function () {

      axios.get(Routes.online_list_date_ranges_price_lists_path({
        format: "json",
      })).then(({data: {start_date, end_date}}) => {

        this.end_season = new Date(end_date)
        this.start_season = new Date(start_date)
        if(this.start_season<Date.now()){
          this.start_season = new Date()
        }
        this.$refs.date_picker.reRender()
        this.loading_calendar = false

      }).catch(() => {
        this.loading_calendar = false
      });

      let checkin = null, checkout = null;
      if (this.value) {
        if (this.value.checkin) {
          checkin = this.value.checkin;
          if (_.isString(checkin)) {
            checkin = new Date(checkin);
          }
        }

        if (this.value.checkout) {
          checkout = this.value.checkout;
          if (_.isString(checkout)) {
            checkout = new Date(checkout);
          }
        }
      }

      return {
        internal_data: {
          checkin: checkin,
          checkout: checkout
        },
        parsed_data: {
          checkin: null,
          checkout: null
        },
        loading_calendar:true,
        start_season: new Date("2021-03-23"),
        end_season: new Date("2021-10-23")
      }
    },
    computed:{
      pickerTranslations() {
        return {
          night: 'Night',
          nights: 'Nights',
          'day-names': this.$t("date.abbr_day_names"),
          'check-in': 'Check-in',
          'check-out': 'Check-Out',
          'month-names': this.$t("date.month_names").filter((el)=> el !== null),
        }

      }
    },
    methods: {

      checkin(v) {
        this.parsed_data.checkin = moment(v).format();
        this.emit_change();
      },
      checkout(v) {
        this.parsed_data.checkout = moment(v).format();
        this.emit_change();
      },
      emit_change() {
        if (this.parsed_data.checkin && this.parsed_data.checkout) {
          this.$emit("input", this.parsed_data);
        }
      }
    }
  }
</script>