<style scoped>
  .divider{
    height: 1px;
    /*background-color: #FFFFFF;*/
  }
</style>

<template>
  <b-row>
    <b-col>
      <b-row class="pb-3">
        <b-col md="6" class="pt-3 pt-lg-0">
          <div class="font-size-14 label_data_top color-1">{{trans("name")}}</div>
          <div class="font-size-24  col-12 px-lg-0">
            {{ name }}
          </div>
        </b-col>
        <b-col md="6" class="pt-3 pt-lg-0">
          <div class="font-size-14 label_data_top color-1">{{trans("surname")}}</div>
          <div class="font-size-24  col-12 px-lg-0">
          {{surname}}
          </div>
        </b-col>

        <b-col md="12" class="py-lg-2">
          <div class="divider"></div>
        </b-col>

        <b-col md="6" class="pt-3 pt-lg-0">
          <div class="font-size-14 label_data_top color-1">{{trans("address")}}</div>
          <div class="font-size-24  col-12 px-lg-0">
            {{address}}
          </div>

        </b-col>

        <b-col md="6" class="pt-3 pt-lg-0">
          <div class="font-size-14 label_data_top color-1">{{trans("city")}}</div>
          <div class="font-size-24  col-12 px-lg-0">
            {{city}}
          </div>

        </b-col>

        <b-col md="12" class="py-lg-2">
          <div class="divider"></div>
        </b-col>

        <b-col md="6" class="pt-3 pt-lg-0">
          <div class="font-size-14 label_data_top color-1">{{trans("zip_code")}}</div>

          <div class="font-size-24  col-12 px-lg-0">
              {{zip_code}}
          </div>

        </b-col>
        <b-col md="6" class="pt-3 pt-lg-0">
          <div class="font-size-14 label_data_top color-1">{{trans("province")}}</div>
          <div class="font-size-24  col-12 px-lg-0">
             {{province}}
          </div>
        </b-col>

        <b-col md="12" class="py-lg-2">
          <div class="divider  col-12 px-lg-0"></div>
        </b-col>


        <b-col md="6" class="pt-3 pt-lg-0">
          <div class="font-size-14 label_data_top color-1">{{trans("state_id")}}</div>
          <div class="font-size-24  col-12 px-lg-0">
             {{state_id}}
          </div>
        </b-col>
        <b-col md="6" class="pt-3 pt-lg-0">
          <div class="font-size-14 label_data_top color-1">{{trans("telephone")}}</div>
          <div class="font-size-24  col-12 px-lg-0">
             {{telephone}}
          </div>
        </b-col>

        <b-col md="12" class="py-lg-2">
          <div class="divider"></div>
        </b-col>

        <b-col md="6" class="pt-3 pt-lg-0">
          <div class="font-size-14 label_data_top color-1">{{trans("email")}}</div>
          <div class="font-size-24  col-12 px-lg-0">
             {{email}}
          </div>
        </b-col>
        <b-col md="6" class="pt-3 pt-lg-0">
          <div class="font-size-14 label_data_top color-1">{{trans("mess")}}</div>
          <div class="font-size-24  col-12 px-lg-0">
             {{mess}}
          </div>
        </b-col>

      </b-row>
<!--      {{trans("name")}}:{{name}}<br>-->
<!--      {{trans("surname")}}:{{surname}}<br>-->
<!--      {{trans("address")}}:{{address}}<br>-->
<!--      {{trans("city")}}:{{city}}<br>-->
<!--      {{trans("zip_code")}}:{{zip_code}}<br>-->
<!--      {{trans("province")}}:{{province}}<br>-->
<!--      {{trans("state_id")}}:{{state_id}}<br>-->
<!--      {{trans("telephone")}}:{{telephone}}<br>-->
<!--      {{trans("email")}}:{{email}}<br>-->
<!--      {{trans("mess")}}:{{mess}}<br>-->
    </b-col>
  </b-row>
</template>

<script>
  export default {
    name: "DatiPrenotazione",
    props: [
      'name',
      'surname',
      'address',
      'city',
      'zip_code',
      'province',
      'state_id',
      'telephone',
      'email',
      'mess'
    ]
  }
</script>

