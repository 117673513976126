<style scoped>
  .border-top-black {
    border-top: dashed 1px #000000;
  }

  .back_light {
    background-color: rgba(234, 248, 255, 0.3);
  }

  .remove_btn{
    background-color: #000000;
    width: 1.2rem;
    height: 1.2rem;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    position: absolute;
    right: -1rem;
    top: 50%;
    transform: translateY(-50%);
  }
</style>

<template>
  <div class="back_light">
  <div class="p-3">
    <b-row class="m-0" >
      <b-col class="">
        <div class="font-size-20 color-2 pb-3">
          {{trans("carrello")}}
        </div>

        <div class="font-size-14 label_data_top color-1 pb-2">
          <b-row class="pb-1 mx-0">
            <b-col cols="5" class="pl-0" >
              {{trans("prodotto")}}
            </b-col>
            <b-col cols="4">
              {{trans("quantita")}}
            </b-col>
            <b-col cols="3" class="text-right">
              {{trans("prezzo")}}
            </b-col>
          </b-row>
        </div>

        <div v-for="l in listino" :key="l.codice">
          <div class="row pb-1 mx-0">
            <div   class="col-5 pl-0">
              {{l.descrizione}}
            </div>
            <div class="col-2 col-sm-4 pr-0 ">
              {{l.qta}}
            </div>
            <div  class="col-5 col-sm-3 text-right position-relative">
              {{l.netto | currency}}
              <button class="remove_btn" v-if="!read_only && l.is_extra_service" @click="event_bus.$emit('remove_extra',l.codice)">&times;</button>
            </div>
          </div>
        </div>

        <b-row class="pb-1 color-1 font-size-16 font-12-mob mx-0">
          <b-col class="border-top-black  pt-1 pl-0" cols="8">
            <strong>{{trans('totale')}}</strong>
          </b-col>

          <b-col class="border-top-black  pt-1 text-right" cols="4">
            <strong> {{solution_exposer.totale_trattamento | currency}}</strong>
          </b-col>
        </b-row>

        <b-row class="pb-1 color-1 font-size-16 font-12-mob m-0">
          <b-col cols="8" class="pl-0">
            <strong> {{trans('caparra_richiesta')}}</strong>
          </b-col>

          <b-col cols="4" class="text-right">
            {{solution_exposer.acconto | currency}}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
  </div>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    name: "Basket",
    props: {
      read_only: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapState(['selected_solution']),
      solution_exposer() {
        return this.selected_solution.solution_exposer;
      },
      listino() {
        return this.solution_exposer.listino;
      }
    }
  }
</script>

