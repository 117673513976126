<style scoped>

</style>

<template>
  <b-row>
    <b-col md="12">
      <HeaderSoluzionePrezzi
         :data_arrivo="solution_search.data_range.checkin"
         :data_partenza="solution_search.data_range.checkout"
         :sistemazione="solution_search.sistemazione_human"
         :occupanti="solution_search.n_pax"
         :eta_partecipanti="solution_search.pax_ages"
         @reset="event_bus.$emit('reset_to_initial_search')"></HeaderSoluzionePrezzi>
    </b-col>
    <hr>
    <b-col md="12" v-if="precise_solutions.length>0">
      <ListElementSolution v-for="x in precise_solutions" :solution="x" :key="x.id"
                           @selected_for_information="event_bus.$emit('information_solution_selected',arguments[0])"
                           @selected="event_bus.$emit('solution_selected',arguments[0])"></ListElementSolution>

    </b-col>
    <b-col md="12" v-else>

      <b-row>
        <b-col>
          <div class="row">
            <div class="col-12 col-lg-2 py-3 back-col-0">
              <div class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="60.5" height="60.5" viewBox="0 0 60.5 60.5">
                  <path id="Path_597" data-name="Path 597" d="M34.25,64.5h0A30.25,30.25,0,1,1,64.5,34.25,30.249,30.249,0,0,1,34.25,64.5Zm0-2.42A27.83,27.83,0,1,0,6.42,34.25,27.831,27.831,0,0,0,34.25,62.08ZM34.2,45.14A19.713,19.713,0,0,0,20.94,49.98V47.56A19.713,19.713,0,0,1,34.2,42.72,20.1,20.1,0,0,1,47.56,47.56v2.42A20.091,20.091,0,0,0,34.2,45.14Zm10.021-26.7-1.353,2.006L50.9,25.86l1.353-2.006ZM16.25,23.854,17.6,25.86l8.025-5.414L24.275,18.44Zm7.11,6.766a2.42,2.42,0,1,0-2.42-2.42,2.421,2.421,0,0,0,2.42,2.42Zm21.78,0a2.42,2.42,0,1,0-2.42-2.42,2.421,2.421,0,0,0,2.42,2.42Z" transform="translate(-4 -4)" fill="#fff"/>
                </svg>
              </div>
            </div>
            <div class="col-12 col-lg-10">
              <div class="font-size-30 color-1 font-weight-bold">
                {{trans('titolo')}}
              </div>
              <div class="font-size-24 line-h-2 color-1 pb-3" v-html=" trans('testo')"></div>
              <div class="orange_btn color-white font-size-14">
                <button class="inner_btn" @click="event_bus.$emit('generic_request')">
                  {{trans('richiesta_generica')}}
                </button>
              </div>
            </div>
          </div>


        </b-col>
      </b-row>

      <b-row v-if="alternative_solutions.length>0">
        <b-col md="12">

          <div class="row">
            <div class="col-12 back-col-9 my-5">
              <div class="font-size-30 color-1">
                {{trans('titolo_2')}}
              </div>
              <div class="font-size-16 line-h-2 pb-3" v-html=" trans('testo_2')"></div>
            </div>
          </div>



          <ListElementSolution v-for="x in alternative_solutions" :solution="x" :key="x.id"
                               @selected_for_information="event_bus.$emit('information_solution_selected',arguments[0])"
                               @selected="event_bus.$emit('solution_selected',arguments[0])"></ListElementSolution>

        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <DiffDateLoader
              :data_arrivo="solution_search.data_range.checkin"
              :data_partenza="solution_search.data_range.checkout"
              :solutions_category="solution_search.solutions_category"
              :solution_id="solution_search.solution"
          ></DiffDateLoader>
        </b-col>
      </b-row>


    </b-col>
  </b-row>
</template>

<script>

  import HeaderSoluzionePrezzi from "./soluzione_prezzi/HeaderSoluzionePrezzi";
  import ListElementSolution from "./soluzione_prezzi/ListElement";
  import {mapState} from "vuex";
  import {setAxiosCSRF} from "../../helpers";
  import DiffDateLoader from "./soluzione_prezzi/DiffDateLoader.vue";

  const axios = setAxiosCSRF();


  export default {
    name: "SoluzionePrezzi",
    components: {
      DiffDateLoader,
      HeaderSoluzionePrezzi,
      ListElementSolution,
    },
    props: {
      solutions: {
        type: Array,
        default() {
          return [];
        }
      }
    },
    data() {

      if (this.solutions.length == 0) {

        axios.get(Routes.last_search_results_bookings_solutions_path({
          format: "json",
          locale: window.current_locale
        })).then((request) => {

          if (request.data.success) {
            this.inner_data.solutions = request.data.results;
          }

        })

      }

      return {
        inner_data: {
          solutions: this.solutions
        }
      }
    },
    computed: {
      ...mapState(['solution_search']),
      /**
       Soluzioni con la data specifica
       */
      precise_solutions() {
        return _.filter(this.inner_data.solutions, {precise: true});
      },
      /**
      Soluzioni con date alternative
       */
      alternative_solutions() {
        return _.filter(this.inner_data.solutions, {precise: false});
      }
    }
  }
</script>

